<template>
    <div>
    <!-- ***************产品中心预算v8banner板块***************** -->
    <div id="productHeader">
        <div class="coverfontWidthControler">
            <div class="coverfont">
                <p class="coverfont_title">全面预算内控平台v8</p>
                <p class="coverfont_sec_title">实现"全面、全额、全员"的精细化预算管理要求</p>
                <div class="coverfontmsg">
                    <div class="coverfontmsgup"><span>预算申报</span><span>上会分析</span><span>预算管理</span><span>报销管理</span></div>
                    <div class="coverfontmsgdown"><span>支付管理</span><span>移动平台</span><span>报表分析</span><span>票据识别</span></div>
                </div>
            </div>
            <img src="../assets/css/images/ysbanner.png" class="bannerImg">
        </div>
    </div>
    <div class="bodyProduct">
        <!-- ***************产品介绍***************** -->
        <Introduce :title="introduceTitle" :sectitle="introducesecTitle" :msg="introductionMsg"></Introduce>
        <!-- ***************功能模块***************** -->
        <div class="moduleOptionBox">
            <div class="titleBox">
                <p class="firstTitle">功能模块</p>
                <p class="secTitle">Functional&nbsp;module</p>
            </div>

            <div class="moduleMsg">
                <el-row :gutter="20">
                    <el-col :span="6" v-for="item in moduleArr" :key="item.id">
                        <div class="moduleBox" :type="item.type">
                            <p class="moduleTitle">{{item.title}}</p>
                            <p class="moduleInfo" v-for="(infomation,index) in item.info" :key="index">{{infomation.name}}</p>
                        </div>
                    </el-col>
                  </el-row>
            </div>

            <img class="modulefg" src="../assets/css/images/fg.png" alt="">
        </div>

        <!-- ***************产品特点***************** -->
        <Features :title="featuresTitle" :sectitle="featuressecTitle" :features="features"></Features>

        <productImg :productImgMsgArr="productImgMsgArr" :productImgLink="productImgLink"></productImg>

        <div class="productImgBox2">
            <div class="productImgBox2Msg">
                <p class="productImgBox2MsgTitle">预算编制全员参与</p>
                <p class="productImgBox2MsgInfo">预算涉及医院经营活动的方方面面、各个环节，而这些方方面面、各个环节的工作都是由医院不同的部门和个人分担的。</p>
                <p class="productImgBox2MsgInfo">就所承担的工作而言，预算的实际执行者应当是最为熟悉情况的。实际上，预算编制的水平如何，如何去完成预算，他们最有发言权。</p>
            </div>
            <img src="../assets/css/images/ysbz.png">
        </div>
    </div>
</div>
</template>      

<script>
    import Introduce from '../components/Introduce'
    import Features from '../components/Features'
    import productImg from '../components/productImg'
    export default{
        name:"productsYs",
        data(){
            return{
                moduleArr:[
                    {title:"基础设置",id:"1",type:"1",info:[{name:"预算科室"},{name:"预算科目"},{name:"预算类型"},{name:"单据提示文案"},{name:"预算权限"},{name:""}]},
                    {title:"预算编报",id:"2",type:"2",info:[{name:"分类型编报"},{name:"预算科室编制"},{name:"归口科室编制"},{name:"预算申报查询"},{name:""},{name:""}]},
                    {title:"预算控制",id:"3",type:"3",info:[{name:"上会分析报表"},{name:"科目调整"},{name:"项目预警"},{name:"项目禁用"},{name:""},{name:""}]},
                    {title:"预算调整",id:"4",type:"4",info:[{name:"日常调整"},{name:"追加调整"},{name:"划拨调整"},{name:"拨入调整"},{name:""},{name:""}]},
                    {title:"预算执行及支付",id:"5",type:"5",info:[{name:"报销执行"},{name:"取数核销执行"},{name:"手工核销执行"},{name:"网银支付"},{name:""},{name:""}]},
                    {title:"预算分析",id:"6",type:"6",info:[{name:"收支平衡表"},{name:"科目科室关联表"},{name:"项目上会表"},{name:"科目项目关联表"},{name:"科目执行统计表"},{name:"项目执行统计表"}]},
                ],

                introduceTitle:'产品介绍',
                introducesecTitle:'Product&nbsp;introduction',
                introductionMsg:[{msg:'医院全面预算内控管理平台是结合预算二级/三级申报、上会分析、预算下达、预算管理、报销执行、网银支付、分析统计，七维一体的综合性内控管理平台，实现"全面、全额、全员"的精细化预算管理要求，为医院提供智能化预算预警，科学化分析报告，建立以精细化、一体化为基础的运营管理体系。'},
                                {msg:'医院全面预算内控管理平台主要包括：预算申报、上会分析、预算管理、报销管理、支付管理、移动平台、报表分析、票据识别八大核心模块。'}],

                featuresTitle:'产品特点',
                featuressecTitle:'Product&nbsp;features',
                features:[
                    {id:'1',title:'预算分类管理',msg:'按业务对预算分类管理，更贴切医院实际场景，同时也将预算的控制范围变得更广，最终实现全院全业务全流程预算控制。',img:require('../assets/css/images/ysflgl.png'),group:'1'},
                    {id:'2',title:'二级/三级预算体系',msg:'支持二级/三级申报的双模式，满足不同医院的编报需求。支持二上二下，同时提供往年数据辅助编报、在线审批、多维度汇总报表。',img:require('../assets/css/images/ejsjystx.png'),group:'1'},
                    {id:'3',title:'预算年中调整',msg:'当预算金额不足或项目取消等原因需要进行预算追加、追减；预算调整后可形成历史记录信息，便于统计查询与责任追溯。',img:require('../assets/css/images/ysnztz.png'),group:'1'},
                    {id:'4',title:'本地化票据平台',msg:'帮助医院实现票据无纸化管理；结合仁享预算管理软件实现发票验真验重、防恶意冲红。',img:require('../assets/css/images/bdhpjpt.png'),group:'2'},
                    {id:'5',title:'打通全院一体化费用管理',msg:'例如医院采购招标、合同签署及付款的预算控制，货品出入库的预算控制与核销，员工工资奖金分配发放金额控制、差旅申请/报销等费用事前控制，科研专项的费用管理...',img:require('../assets/css/images/dtqyythfygl.png'),group:'2'},
                    {id:'6',title:'辅助管理决策',msg:'系统预置多种常用预算查询报表，可以对预算总额、执行进度、调整变更等数据进行方便的统计与分析，辅助业务决策。',img:require('../assets/css/images/fzgljc.png'),group:'2'}
                ],
                productImgMsgArr:[{title:'预算内控管理',info:[{name:'项目科目双重管理'},{name:'冻结/执行记录查询'},{name:'预算执行预警'},{name:'预算使用权限'},{name:'年中预算调整'}]}],
                productImgLink:require('../assets/css/images/ysnkgl.png') 
            }
        },
        mounted(){
            
        },
        components:{Introduce,Features,productImg}
        
    }
</script>

<style lang='scss' scoped>
    .bodyProduct{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .moduleOptionBox{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top:100px;
    }

    .productImgInfo{
        font-size:19px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .productImgInfo img{
        height:19px;
        margin-right:5px;
    }
    .productImgBox2{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width:80%;
        margin-top:100px;
    }
    .productImgBox2 img{
        width:600px;
        transition: all 0.5s;
    }
    .productImgBox2 img:hover{
        transform: scale(1.1);
    }
    .productImgBox2Msg{
        width:43%;
    }
    .productImgBox2MsgTitle{
        font-size:30px;
    }
    .productImgBox2MsgInfo{
        font-size: 16px;
        color: #767676;
        display: block;
        text-indent:2em;
    }
    .productImgMsg{
        position: absolute;
        right: 33%;
        top: 23%;
        color: #fff;
    }
    .productImgTitle{
        font-size: 34px;
        font-weight: 800;
    }
    .titleBox{
        margin-bottom:30px;
    }
    
    .modulefg{
        width: 70%;
        margin-top: 30px;
    }
    .moduleBox[type="1"] .moduleTitle{
        background-color: rgb(96,150,230);
    }
    .moduleBox[type="1"] .moduleInfo{
        background-color:rgb(231,239,251);
    }

    .moduleBox[type="2"] .moduleTitle{
        background-color: rgb(88,182,229);
    }
    .moduleBox[type="2"] .moduleInfo{
        background-color:rgb(230,244,251);
    }

    .moduleBox[type="3"] .moduleTitle{
        background-color: rgb(86,202,149);
    }
    .moduleBox[type="3"] .moduleInfo{
        background-color:rgb(230,247,239);
    }
    
    .moduleBox[type="4"] .moduleTitle{
        background-color: rgb(255,186,85);
    }
    .moduleBox[type="4"] .moduleInfo{
        background-color:rgb(255,245,230);
    }

    .moduleBox[type="5"] .moduleTitle{
        background-color: rgb(241,136,112);
    }
    .moduleBox[type="5"] .moduleInfo{
        background-color:rgb(253,237,234);
    }

    .moduleBox[type="6"] .moduleTitle{
        background-color: rgb(236,95,116);
    }
    .moduleBox[type="6"] .moduleInfo{
        background-color:rgb(252,231,234);
    }
    .moduleBox p{
        margin:0;
        text-align: center;
        height:50px;
        line-height:50px;
        width:100%;
    }
    .moduleTitle{
        color:#fff;
        font-size:18px;
        font-weight: 600;
        border-radius:4px 4px 0 0;
    }
    .moduleInfo{
        color:#333;
        font-size: 15px;
    }
    .moduleMsg{
        width:67%;
    }
    .el-row {
    margin-bottom: 20px;
        &:last-child {
        margin-bottom: 0;
        }
    }
    .el-col-6{
        width:16.66% !important;
    }
    .el-col {
        border-radius: 4px;
    }
    .bg-purple-dark {
        background: #99a9bf;
    }
    .bg-purple {
        background: #d3dce6;
    }
    .bg-purple-light {
        background: #e5e9f2;
    }
    .grid-content {
        border-radius: 4px;
        min-height: 36px;
    }
    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }    
</style>