<template>
<div>
    <!-- ***************产品中心绩效方案板块***************** -->
    <div id="financialHeader">
        <div class="coverfontWidthControler">
            <div class="coverfont">
                <p class="coverfont_title">绩效方案/绩效系统</p>
                <p class="coverfont_sec_title">打造医院全面、科学、合理的绩效管理和评价体系</p>
            </div>
            <img src="../assets/css/images/jxfabanner.png" class="bannerImg">
        </div>
    </div>
    <div class="bodyProduct">
        <!-- ***************产品介绍***************** -->
        <Introduce :title="introduceTitle" :sectitle="introducesecTitle" :msg="introductionMsg"></Introduce>
        

        <!-- ***************产品特点***************** -->
        <Features :title="featuresTitle" :sectitle="featuressecTitle" :features="features"></Features>

        <div class="productImgBox">
            <div class="titleBox">
                <p class="firstTitle">考核指标</p>
                <p class="secTitle">Assessment indicators</p>
            </div>

            <div class="productImgBoxWidthControler">
                <div class="productMsgBox" v-for="(item,index) in productMsgArr" :key="index" :type="item.type">
                    <div class="msgtitlebox"><span class="productMsgtitle2">{{item.title}}</span></div>
                    
                    <div class="productMsginfobox">
                        <div class="infobox" v-for="(item2,index) in item.info" :key="index">
                            <span class="productMsgbigtitle">{{item2.title}}</span>
                            <span class="productlittleMsg" v-for="(item3,index) in item2.msg" :key="index">{{item3.param}}</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>


        <!-- ***************理论依据***************** -->
        <div class="schemeBox">
            <div class="schemeBoxWidthControler">
                <div class="titleBox">
                    <p class="firstTitle">理论依据</p>
                    <p class="secTitle">Theoretical basis</p>
                </div>
                <div class="schemeInfoBox">    
                    <div class="schemeInfonoshadow">
                        <div class="schemeImg"><img src="../assets/css/images/llyj.png" alt=""></div>
                        <div class="schemeMsg">
                            <div class="schemeMsgTitle">系统</div>
                            <div class="schemeEssay">根据医院员工工作岗位和工作性质的不同划分为医师、护理、技师三个不同的系统。</div>
                        </div>
                    </div>
                </div>
                    
                <div class="schemeInfoBox schemeBottomBox">
                    <div class="schemeInfonoshadow">
                        <div class="schemeImg"><img src="../assets/css/images/llyj.png" alt=""></div>
                        <div class="schemeMsg">
                            <div class="schemeMsgTitle">关键绩效指标</div>
                            <div class="schemeEssay">根据不同的工作KPI(关键绩效指标），分析其工作所需要的技术、时间、风险程度、消耗的资源与成本、结果质量等，再根据这些特点设计相应的绩效工资分配模型。</div>
                        </div>
                    </div>
    
                    <div class="schemeInfonoshadow">
                        <div class="schemeImg"><img src="../assets/css/images/llyj.png" alt=""></div>
                        <div class="schemeMsg">
                            <div class="schemeMsgTitle">绩效考核量表</div>
                            <div class="schemeEssay">辅以具有针对性的绩效考核量表进行绩效工资分配。</div>
                        </div>
                    </div>
                </div>

                
            </div>
            
        </div>
        <div class="achievementsBottom"><img src="../assets/css/images/achievementsBottom.png" alt=""></div>
    </div>
</div>
</template>      

<script>
    import Introduce from '../components/Introduce'
    import Features from '../components/Features'
    export default{
        name:"Archievements",
        data(){
            return{
                introduceTitle:'产品介绍',
                introducesecTitle:'Product&nbsp;introduction',
                introductionMsg:[{msg:'绩效管理系统是基于医院战略目标，以人力资源管理为基础，通过有效整合医院信息资源，建立医院标准化管理数据中心，挖掘数据信息，充分利用RBRVS、平衡记分卡、360度考核、DRGs付费、标化工作量等现代管理工具，对目标的执行进行管理、考核、分析、评价，最终结合科室经济效益，通过奖金分配这一经济杠杆实现对员工的有效激励。系统为医院领导、部门领导、科主任提供了一个统一的查询监控平台, 实现对医院、科室、班组、个人等各层面建设目标的绩效管理，打造医院全面、科学、合理的绩效管理和评价体系。'},],

                featuresTitle:'产品特点',
                featuressecTitle:'Product&nbsp;features',
                features:[
                    {id:'1',title:'岗位绩效考核（工资）',msg:'通过有效整合医院信息资源，建立医院标准化管理数据中心',img:require('../assets/css/images/gwjx.png'),group:'1'},
                    {id:'2',title:'RBRVS工作量绩效理论应用',msg:'根据不同的工作KPI分析，辅以具有针对性的绩效考核量表进行绩效工资分配',img:require('../assets/css/images/ctzt.png'),group:'1'},
                    {id:'3',title:'DRG/DIP病种费用成本',msg:'DRG/DIP关键指标与工作量绩效相互结合',img:require('../assets/css/images/bzfy.png'),group:'2'},
                    {id:'4',title:'绩效核算模型',msg:'住院/门诊医师绩效、病房/非病房工作绩效、医技科室/技术员绩效..',img:require('../assets/css/images/jxhs.png'),group:'2'},
                    {id:'5',title:'与国考相结合',msg:'与国家战略目标一致，帮助医院提升医疗质量，促进医院持续发展，提高医院运营效率，提高患者满意度',img:require('../assets/css/images/ygkxjh.png'),group:'2'}],

                productMsgArr:[
                {title:'医师',info:[{title:'病房医师',msg:[{param:'RBRVS绩效点数'},{param:'出院病患疾病严重(CMI)'}]},{title:'门诊医师',msg:[{param:'RBRVS绩效点数'}]}],type:'1'},
                {title:'护理',info:[{title:'病房护理',msg:[{param:'直接工作（RBRVS绩效点数）'},{param:'间接工作量（床日总数、周转体现）'}]},{title:'非病房护理',msg:[{param:'RBRVS绩效点数'},{param:'服务量（人次、台次）'}]}],type:'2'},
                {title:'医技',info:[{title:'医技医师',msg:[{param:'RBRVS绩效点数'}]},{title:'医技技术人员',msg:[{param:'检查治疗人次/部位'}]}],type:'3'},
                {title:'行政后勤',info:[{title:'部门 岗位',msg:[{param:'RBRVS绩效点数'}]},{title:'职称 学历',msg:[{param:'RBRVS绩效点数'}]}],type:'4'}]
                
            }
        },
        mounted(){
            
        },
        components:{Introduce,Features}
        
    }
</script>

<style lang='scss' scoped>
    .achievementsBottom{
        width: 60%;
        margin-top: 50px;
    }
    .achievementsBottom img{
        width: 100%;
    }
    .schemeBox{
        width:100%;
        background-image: url(../assets/css/images/bgachieve.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right 0;
    }
    .schemeBoxWidthControler .titleBox{
        width: 100%;
        color: #333;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 2;
    }  
    .schemeMsgTitle{
        font-weight: 800;
        font-size: 16px;
    }
    .schemeEssay{
        font-size:14px;
        margin-top:9px;
    }
    .schemeBottomBox{
        margin-top:4%;
    }
    .schemeBoxWidthControler{
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top:50px;
    }
    .schemeInfoBox{
        width:100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
    .schemeInfonoshadow{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border:1px solid #cfcfcf;
        width:45%;
        padding:20px;
        transition: all 0.5s;
        background-color:#fff;
        border-radius:4px;
    }
    .schemeInfonoshadow:hover{
        transform: scale(1.1);
    }
    .schemeImg img{
        width:40px;
    }
    .schemeMsg{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width:87%;
    }
    .infobox2{
        position: absolute;
        display: flex;
        width: 60%;
        top: 40%;
        left: 20%;
        flex-direction: row;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 800;
    }
    .infobox3{
        position: absolute;
        display: flex;
        width: 60%;
        top: 60%;
        left: 20%;
        flex-direction: row;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 800;
    }
    .productMsgbigtitle{
        font-size:1.8rem;
        font-weight: 800;
    }
    .productlittleMsg{
        font-size:1.5rem;
        color:#7a7a7a;
    }
    .productMsginfobox{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 10%;
        padding-left: 10%;
        height:196px;
    }
    .infobox{
        display: flex;
        flex-direction: column;
        margin-top:10px;
    }
    .productMsgBox{
        background-size: contain;
        width: 22%;
        padding-top: 2%;
        background-repeat: no-repeat;
        transition: all 0.5s;
    }
    .productMsgBox:hover{
        transform: scale(1.1);
    }
    .productMsgBox[type="1"]{
        background-image: url(../assets/css/images/ys.png);
    }
    .productMsgBox[type="2"]{
        background-image: url(../assets/css/images/hl.png);
    }

    .productMsgBox[type="3"]{
        background-image: url(../assets/css/images/yj.png);
    }
    .productMsgBox[type="4"]{
        background-image: url(../assets/css/images/xzhq.png);
    }
    .msgtitlebox{
        text-align: center;
    }
    .productMsgPicBox{
        position:absolute;
    }
    .productMsgtitle2{
        font-size: 24px;
        color: #fff;
        font-weight: 700;
    }
    .productMsgtitle{
        font-size: 24px;
        color: #fff;
        font-weight: 700;
        position: absolute;
        top: 8%;
        left: 42%;
    }
    .productImgBox .titleBox{
        width: 100%;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 2;
    }
    .productImgBox .firstTitle{
        font-size: 30px;
        font-weight: 900;
    }
    .productImgBox .secTitle{
        font-size: 16px;
        color:#fff;
    }


    .bodyProduct{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .productImgInfo{
        font-size:19px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .productImgInfo img{
        height:19px;
        margin-right:5px;
    }
    .productImgMsg{
        position: absolute;
        right: 18%;
        top: 29%;
        color: #fff;
    }
    .productImgTitle{
        font-size: 34px;
        font-weight: 800;
    }
    .productImgBox{
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        width: 100%;
        background-image: url(../assets/css/images/khzb.png);
        background-repeat: no-repeat;
        height: 571px;
        align-items: center;
        justify-content: space-evenly;
        background-size: cover;
    }
    .productImgBoxWidthControler{
        width: 69%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        height:300px;
    }
    .productMsgBox img{
        height:300px;
    }
    .titleBox{
        margin-bottom:30px;
    }

    @media (max-width:1789px){
        .productMsginfobox{
            margin-top:7%;
        }
        .productMsgbigtitle{
            font-size: 1.7rem;
        }
        .productlittleMsg{
            font-size: 1.4rem;
        }
    }

    @media (max-width:1644px){
        .productMsgbigtitle{
            font-size: 1.6rem;
        }
        .productlittleMsg{
            font-size: 1.3rem;
        }
    }

    @media (max-width:1525px){
        .productMsgbigtitle{
            font-size: 1.5rem;
        }
        .productlittleMsg{
            font-size: 1.2rem;
        }
    }

    @media (max-width:1414px){
        .productMsginfobox{
            margin-top:1%;
        }
        .productMsgbigtitle{
            font-size: 1.4rem;
        }
        .productlittleMsg{
            font-size: 1.1rem;
        }
    }
</style>