<template>
    <div class="footerBox">
        <div class="footerWithControler">
            <div class="leftbox">
                <p class="leftmsg litmsg">业务咨询电话:</p>
                <p class="leftmsg phonemsg">0571-870-191-99</p>
                <!-- <p>备案号:浙ICP备17025136号-1</p> -->
                <a target="_blank" href="http://beian.miit.gov.cn" class="leftmsg litmsg2">浙ICP备17025136号-1</a >
            </div>
            <div class="midbox">
                <div class="midleft">
                    <p class="midtitle">关于仁享</p>
                    <p class="litmsg" @click="toLink('aboutUs','4')">了解仁享</p>
                    <p class="litmsg" @click="toLink('Solution','3')">解决方案</p>
                </div>
                <div class="midright">
                    <p class="midtitle">产品</p>
                    <p class="litmsg" @click="toLink('productsYs',['2','1'])">全面预算内控平台v8</p>
                    <p class="litmsg" @click="toLink('Financial',['2','2'])">应付款/供应商平台</p>
                    <p class="litmsg" @click="toLink('ticketPlatform',['2','3'])">票务平台</p>
                    <p class="litmsg" @click="toLink('Ebank',['2','4'])">网银资金池</p>
                    <p class="litmsg" @click="toLink('Accounting',['2','5'])">财务核算系统</p>
                    <p class="litmsg" @click="toLink('Archives',['2','6'])">电子档案系统</p>
                    <p class="litmsg" @click="toLink('personnelFunds',['2','7'])">人员经费平台</p>
                    <p class="litmsg" @click="toLink('Achievements',['2','8'])">绩效系统</p>
                </div>
            </div>
            <div class="rightbox">
                <img src="../../assets/css/images/aboutRX.png" alt="">
                <p>关注仁享</p>
            </div>
        </div>
        <navBar></navBar>
    </div>
</template>

<script>
    import navBar from  '../navBar'
    export default{
        name:"Footer",
        components:{navBar},
        data(){
            return{

            }
        },
        methods:{
            toLink(target,index){
                this.$emit('indexGetter',index)
                this.$router.push({
                    path:'/'+target,
                    query:{}
                })

                document.querySelector("#forHeader").scrollTop = 0

            },
        }
    }
</script>

<style>
    .rightbox img{
        width:200px;
    }
    .footerWithControler{
        width:80%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
    }
    .footerBox{
        background-color: #1b1a1a;
        height: 400px;
        width: 100%;
        color: #fff;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items:flex-start;
        padding-top:70px;
        margin-top:100px;
    }
    .midbox{
        display: flex;
        width: 28%;
        justify-content: space-between;
    }
    .rightbox{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .rightbox p{
        margin-top:20px;
    }
    .litmsg{
        color:rgb(170, 170, 170);
        font-size:17px;
    }
    .litmsg2{
        font-size:14px;
        color:#fff;
    }
    .litmsg2:hover{
        font-size:14px;
        color:#e9e9e9;
        text-decoration:none;
    }
    .midbox .litmsg{
        cursor:pointer;
    }
    .midbox .litmsg:hover{
        color:#fff;
    }
    .phonemsg{
        font-size:30px;
        font-weight:700;
    }
    .midtitle{
        font-size:20px;
        font-weight: 600;
    }
</style>