// 该文件专门用于创建整个应用的路由器
import VueRouter from 'vue-router'
//引入组件
import Home from '../pages/Home'
import productsYs from '../pages/productsYs'
import Financial from '../pages/Financial'
import Accounting from '../pages/Accounting'
import Archives from '../pages/Archives'
import Achievements from '../pages/Achievements'
import personnelFunds from '../pages/personnelFunds'
import ticketPlatform from '../pages/ticketPlatform'
import Ebank from '../pages/Ebank'
import aboutUs from '../pages/aboutUs'
import Solution from '../pages/Solution'

//解决编程式导航连续点击的报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

//创建并暴露一个路由器

let router = new VueRouter({
	mode: 'hash',
	routes:[
        {
			//主页
			path:'/Home',
			component:Home,
			meta:{bannerType:'1',nav:'1',name:'杭州仁享软件有限公司'}
		},
        {
			//全面预算内控平台v8
			path:'/productsYs',
			component:productsYs,
			meta:{bannerType:'2',nav:'2',name:'全面预算内控平台v8'}
		},
		{
			//应付款/供应商平台
			path:'/Financial',
			component:Financial,
			meta:{bannerType:'3',nav:'2',name:'应付款/供应商平台'}
		},
		{
			//财务会计核算系统
			path:'/Accounting',
			component:Accounting,
			meta:{bannerType:'4',nav:'2',name:'财务会计核算系统'}
		},
		{
			//电子档案系统
			path:'/Archives',
			component:Archives,
			meta:{bannerType:'5',nav:'2',name:'电子档案系统'}
		},
		{
			//人员经费平台
			path:'/personnelFunds',
			component:personnelFunds,
			meta:{bannerType:'6',nav:'2',name:'人员经费平台'}
		},
		{
			//绩效系统
			path:'/Achievements',
			component:Achievements,
			meta:{bannerType:'7',nav:'2',name:'绩效系统'}
		},
		{
			//一站式票务平台
			path:'/ticketPlatform',
			component:ticketPlatform,
			meta:{bannerType:'8',nav:'2',name:'一站式票务平台'}
		},
		{
			//网银资金池
			path:'/Ebank',
			component:Ebank,
			meta:{bannerType:'9',nav:'2',name:'网银资金池'}
		},
        {
			//解决方案
			path:'/Solution',
			component:Solution,
			meta:{bannerType:'11',nav:'3',name:'解决方案'}
		},
		{
			//关于我们
			path:'/aboutUs',
			component:aboutUs,
			meta:{bannerType:'12',nav:'4',name:'关于我们'}
		},
        {
            path:'/',
            redirect:'/Home'
        }
	],
	scrollBehavior (to, from, savedPosition) {
		if (savedPosition) {
		  //此处意思 即若回退页面，返回savedPosition，且页面不置顶
		  return savedPosition
		} else {
		  //此处意思 若页面跳转新页面，则页面置顶
		  return { x: 0, y: 0 }
		}
	  },
	  
})

router.afterEach((to, from) => {

})
router.beforeEach((to, from, next) => {
    next();
	document.title = to.meta.name

});

export default router