<template>
<div>
    <!-- ***************产品中心网银资金池板块***************** -->
    <div id="financialHeader">
        <div class="coverfontWidthControler">
            <div class="coverfont">
                <p class="coverfont_title">网银资金池</p>
                <p class="coverfont_sec_title">实现资金划转和归集，提高资金利用效率</p>
            </div>
            <img src="../assets/css/images/ebankbanner.png" class="bannerImg">
        </div>
    </div>
    <div class="bodyProduct">
        <!-- ***************产品介绍***************** -->
        <Introduce :title="introduceTitle" :sectitle="introducesecTitle" :msg="introductionMsg"></Introduce>
        

        <!-- ***************产品特点***************** -->
        <Features :title="featuresTitle" :sectitle="featuressecTitle" :features="features"></Features>

        <!-- <div class="productImgBox">
            <div class="productImgMsg">
                <p class="productImgTitle">网银付款</p>
                <p class="productImgInfo"><img src="../assets/css/images/icon1.png">业务单据自动归集</p>
                <p class="productImgInfo"><img src="../assets/css/images/icon1.png">查询支付进度</p>
                <p class="productImgInfo"><img src="../assets/css/images/icon1.png">自动获取支付凭证</p>
            </div>
        </div> -->
        <productImg :productImgMsgArr="productImgMsgArr" :productImgLink="productImgLink"></productImg>
    </div>
</div>
</template>      

<script>
    import Introduce from '../components/Introduce'
    import Features from '../components/Features'
    import productImg from '../components/productImg'

    export default{
        name:"Ebank",
        data(){
            return{
                introduceTitle:'产品介绍',
                introducesecTitle:'Product&nbsp;introduction',
                introductionMsg:[{msg:'网银资金池管理平台主要包括对全院工资、奖金发放，日常报销支出，设备，药品，耗材等实时在线付款，并能够实现银医自动对账功能。支持多银行多账户，实现银行相关信息管理与维护，包括医院账户管理、交易安全审核规则管理 （设置制单、复核的流程与规则）、供应商收款账户管理等。实现报销系统、合同系统、物资系统等各业务系统与银行之间的业务对接与数据通讯安全，实现线上批量支付与自动获取银行回单。'}],

                featuresTitle:'产品特点',
                featuressecTitle:'Product&nbsp;features',
                features:[
                    {id:'1',title:'银医直连',msg:'能够做到与医院计算机系统的对接，通过医院与银行互动联接，实现资金划转和归集，提高资金利用效率，方便地完成医院系统与银行有关的交易。',img:require('../assets/css/images/hlht.png'),group:'1'},
                    {id:'2',title:'线上支付',msg:'自动生成网银应付款与工资、奖金分配、报销系统对接，完成工资福利报销的在线发放，支持批量支付、多账户支付。',img:require('../assets/css/images/pay.png'),group:'1'},
                    {id:'3',title:'自动采集银行回单',msg:'银行回单通过银医直连的方式，直接将回单数据回显至网银资金池。',img:require('../assets/css/images/zdcj.png'),group:'2'},
                    {id:'4',title:'开放式平台',msg:'支持与医院任意业务系统做对接，提供统一支付途径。',img:require('../assets/css/images/kfspt.png'),group:'2'},
                    {id:'5',title:'数据安全',msg:'采用MD5数据流摘要校验，  确保数据一致；负载均衡，保证高可用性，  降低响应时间。',img:require('../assets/css/images/sjaq.png'),group:'2'}
                ],

                productImgMsgArr:[{title:'网银付款',info:[{name:'业务单据自动归集'},{name:'查询支付进度'},{name:'自动获取支付凭证'}]}],
                productImgLink:require('../assets/css/images/wyfk.png'),
            }
        },
        mounted(){
            
        },
        components:{Introduce,Features,productImg}
        
    }
</script>

<style lang='scss' scoped>
    .bodyProduct{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .titleBox{
        margin-bottom:30px;
    }
</style>