<template>
<div>
    <!-- ***************解决方案板块***************** -->
    <div id="solutionHeader" :class="list[listIndex]">
        <div class="coverfont">
            <p class="coverfont_title">仁享 — 医疗财务信息领域解决方案</p>
            <p class="coverfont_sec_title">全面 财务 共享 数智</p>
        </div>
        <div class="banner"></div>
    </div>
    <div class="bodyProduct">
        <!-- ***************方案概述***************** -->
        <Introduce :title="introduceTitle" :sectitle="introducesecTitle" :msg="introductionMsg"></Introduce>
        
        <!-- ***************业务痛点***************** -->
        <div class="businessPain">
            <Features :title="featuresTitle2" :sectitle="featuressecTitle2" :features="features2"></Features>
        </div>
        <!-- ***************产品特点***************** -->
        <Features :title="featuresTitle" :sectitle="featuressecTitle" :features="features"></Features>

        <!-- ***************方案优势***************** -->
        <div class="schemeBox">
            <div class="titleBox">
                <p class="firstTitle">方案优势</p>
                <p class="secTitle">Scheme&nbsp;advantages</p>
            </div>
                <div class="schemeInfoBox">
                    <div class="schemeInfo">
                        <div class="schemeImg"><img src="../assets/css/images/scheme.png" alt=""></div>
                        <div class="schemeMsg">
                            <div class="schemeMsgTitle">快速响应最新政策</div>
                            <div class="schemeEssay">顺应国家科学化、信息化、无纸化变革趋势，响应最新医疗财政政策带来的变化，迎接智慧医院新时代</div>
                        </div>
                    </div>
    
                    <div class="schemeInfo">
                        <div class="schemeImg"><img src="../assets/css/images/scheme.png" alt=""></div>
                        <div class="schemeMsg">
                            <div class="schemeMsgTitle">健全的业财融合体系</div>
                            <div class="schemeEssay">全面预算、财务会计、成本管理、合同管理、专项管理、报销管理、应付款管理、档案管理、...</div>
                        </div>
                    </div>
                </div>
                
                <div class="schemeInfoBox schemeBottomBox">
                    <div class="schemeInfo">
                        <div class="schemeImg"><img src="../assets/css/images/scheme.png" alt=""></div>
                        <div class="schemeMsg">
                            <div class="schemeMsgTitle">更符合医疗单位的预算管理</div>
                            <div class="schemeEssay">医院的业务区别于企业，仁享专为解决医院的预算管理需求，让预算分类管理，分类执行</div>
                        </div>
                    </div>
    
                    <div class="schemeInfo">
                        <div class="schemeImg"><img src="../assets/css/images/scheme.png" alt=""></div>
                        <div class="schemeMsg">
                            <div class="schemeMsgTitle">显著提高财务工作效率</div>
                            <div class="schemeEssay">线上审批与付款，补贴自动计算，所有凭证自动流转，财务报表一键生成</div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</div>
</template>      

<script>
    import Introduce from '../components/Introduce'
    import Features from '../components/Features'
    export default{
        name:"Solution",
        data(){
            return{
                list: [
                    'solutionChannel_1',
                    'solutionChannel_2',
                    'solutionChannel_3'
                ],
                listIndex: 0, //默认显示第几张图片
                timer: null, //定时器
                introduceTitle:'方案概述',
                introducesecTitle:'Scheme overview',
                introductionMsg:[{msg:'仁享医疗财务体系助力医院实现业财税一体化、无纸化建设，减轻财务工作。以全面预算内控管理为基石，联合成本控制、绩效方案等6大板块，通过有效整合医院信息资源，建立医院标准化管理数据中心，挖掘数据信息，建立医院科学规范的考评体系，达到指导经营、降本增效的目的。'}],

                featuresTitle:'应用场景',
                featuressecTitle:'Use Scenarios',
                features:[
                    {id:'1',title:'线上报销',msg:'员工填报时预算不足则不能提交，发票自动验真伪。手机审批归档，银医直连在线支付，自动生成记账凭证，档案系统自动采集',img:require('../assets/css/images/xsbx.png'),group:'1'},
                    {id:'2',title:'制定明年收入计划',msg:'管理层编制医疗收入战略目标，配合绩效方案具体实施到下面各科室、员工，提高医疗服务质量',img:require('../assets/css/images/zdmnsrjh.png'),group:'1'},
                    {id:'3',title:'经营状况分析',msg:'分析各季度、部门、费用支出情况，监测异常值。分析患者治疗均次盈亏、药耗占比，辅助医院采取措施，提高营收',img:require('../assets/css/images/jyzkfx.png'),group:'1'}],
                
                featuresTitle2:'业务痛点',
                featuressecTitle2:'Business pain points',
                features2:[
                    {id:'1',title:'线下流程无法满足预算内控',msg:'传统线下模式仅能在费用发生以后去记录，无法做到事前控制',img:require('../assets/css/images/xxlc.png'),group:'1'},
                    {id:'2',title:'业财税系统不连通',msg:'采购、库房、HIS等业务系统没有和财务、税务、档案系统打通，信息化建设没有形成闭环，仍存在过多的人工操作',img:require('../assets/css/images/ycs.png'),group:'1'},
                    {id:'3',title:'医疗资源未能科学利用',msg:'检测设备预约等待时间长，导致患者平均住院日期增加，药品耗材使用量未能合理管控，不利于医院在新医保付费制度下的经营',img:require('../assets/css/images/ylzy.png'),group:'1'},
                    {id:'4',title:'凭证归档整理麻烦',msg:'纸质记账凭证、纸质单据、纸质票据，存在耗费纸张、占用空间、容易丢失篡改、查阅不方便等问题',img:require('../assets/css/images/pzgd.png'),group:'1'}]
            }
        },
        mounted(){
            this.setTimer()
        },
        methods:{
            setTimer() {
                this.timer = setInterval(() => {
                    this.listIndex++;
                    if (this.listIndex == this.list.length) {
                        this.listIndex = 0;
                    }
                }, 5000);
            },
        },
        components:{Introduce,Features}
        
    }
</script>

<style lang='scss'>
    .solutionChannel_1{
        background-image: url(../assets/css/images/solutionBanner.png);
    } 
    .solutionChannel_2{
        background-image: url(../assets/css/images/solutionBanner_2.png);
    } 
    .solutionChannel_3{
        background-image: url(../assets/css/images/solutionBanner_3.png);
    } 
    #solutionHeader{
        height: 761px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-size: cover;
        transition:all 1s;
    }
    #solutionHeader .bannerImg{
        width: 26%;
    }
    #solutionHeader .coverfont{
        z-index: 2;
        color: #fff;
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    #solutionHeader .coverfont_sec_title{
        margin-top:20px;
        font-size:30px;
        font-weight: 500;
        width:76%;
    }
    .schemeBox .titleBox{
        width: 100%;
        color: #333;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 2;
    }  
    .schemeMsgTitle{
        font-weight: 800;
        font-size: 16px;
    }
    .schemeEssay{
        font-size:14px;
        margin-top:9px;
    }
    .schemeBottomBox{
        margin-top:4%;
    }
    .schemeBox{
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top:50px;
    }
    .schemeInfoBox{
        width:100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
    .schemeInfo{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0px 0px 11px #92c9ff;
        width:45%;
        padding:20px;
        transition: all 0.5s;
    }
    .schemeInfo:hover{
        transform: scale(1.1);
        box-shadow: 0px 0px 20px #92c9ff;
    }
    .schemeImg img{
        width:40px;
    }
    .schemeMsg{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width:87%;
    }
    .businessPain{
        background-image: url(../assets/css/images/ywtd.png);
        margin-top: 100px;
        height: 761px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .businessPain .titleBox,.businessPain .secTitle,.businessPain .featuresMsg{
        color:#fff;
    }
    .businessPain .featuresMsg{
        background-color: #0080ff;
        border-radius:4px;
        box-shadow:none;
    }
    .bodyProduct{
        display: flex;
        flex-direction: column;
        align-items: center;
    }


    .titleBox{
        margin-bottom:30px;
    }


    @media (max-width:1499px){
        .featuresTitle{
            font-size:15px;
        }
        .featuresInfo{
            font-size:13px;
        }
    }
    @media (max-width:1384px){
        .featuresTitle{
            font-size:13px;
        }
        .featuresInfo{
            font-size:12px;
        }
    }
    @media (max-width:1280px){
        .featuresMsgBox{
            width:90%;
        }
        .featuresTitle{
            font-size:12px;
        }
        .featuresInfo{
            font-size:11px;
        }
    }
</style>