<template>
    <div>
        <!-- ***************业务板块***************** -->
        <!-- <div class="segmentsBox">
            <div class="titleBox">
                <p class="firstTitle">业务板块</p>
                <p class="secTitle">Business&nbsp;Segments</p>
            </div>
            <el-row :gutter="20">
                <el-col :span="6" v-for="item in segmentArr" :key="item.id">
                    <div class="imgBox"><img :src="item.img" :alt="item.name" ></div>
                    <p class="imgmsg">{{item.name}}</p>
                    <p class="imgsecmsg">{{item.secname}}</p>
                </el-col>
              </el-row>
        </div> -->
        <!-- ***************首页banner板块***************** -->
        <div id="homeHeader" :class="list[listIndex]">
            <div class="coverfont">
                <p class="coverfont_title">医疗财务一体化解决方案<br>数智&nbsp;财务&nbsp;共享&nbsp;全面</p>
                <p class="coverfont_sec_title">2014年成立至今,始终专注于医疗财务信息领域</p>
                <div class="coverfont_btn" @click="toLink('Solution','3')">点击查看详情</div>
            </div>
            <div class="banner"></div>
        </div>
        <!-- <div class="segmentsBox">
            <div class="titleBox">
                <p class="firstTitle">业务板块</p>
                <p class="secTitle">Business&nbsp;Segments</p>
            </div>

            <div class="swiperBox segmentsMsgBox">
                <div class="swiper-container segmentsMsgWidthControler">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide segmentsMsg"  v-for="item in segmentArr" :key="item.id">
                            <div class="imgBox" :type="item.type"  @click="toLink(item.link,item.indexArr)"></div>
                            <div class="imgmsgBox">
                                <p class="imgmsg">{{item.name}}</p>
                                <p class="imgsecmsg">{{item.secname}}</p>
                            </div>
                        </div>

                    </div>

                    <div class="swiper-pagination"></div>
                </div>
            </div>

        </div> -->
        <Segments></Segments>
        <!-- ***************功能特点***************** -->
        <div class="featuresBox">
            <div class="titleBox">
                <p class="firstTitle">功能特点</p>
                <p class="secTitle">Functional&nbsp;features</p>
            </div>
            <div class="swiperBox">
                <div class="swiper-container">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide">
                            <img src="../../src/assets/css/images/slide1.png" alt="">
                            <div class="swiper-msg">
                                <p class="swiper-msg-title">全业务费控</p>
                                <p class="swiper-msg-sectitle">覆盖医院采购招标、合同签署及付款、货品出入库、人员经费、差旅等，不仅仅是报销。</p>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <img src="../../src/assets/css/images/slide2.png" alt="">
                            <div class="swiper-msg">
                                <p class="swiper-msg-title">全面无纸化</p>
                                <p class="swiper-msg-sectitle">无纸化报销审批、多票种电子化票据平台、线上合同起草、供应商发票上传、物资系统对接、线上付款、自动生成凭证、电子化档案归集。</p>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <img src="../../src/assets/css/images/slide3.png" alt="">
                            <div class="swiper-msg">
                              <p class="swiper-msg-title">指导经营、辅助决策</p>
                              <p class="swiper-msg-sectitle">预算编制分析、执行分析与预警、科室绩效考核、医疗服务性收入均次盈亏、重点病种成本控制等。</p>
                            </div>
                        </div>   
                    </div>
                    <!-- 如果需要分页器 -->
                    <div class="swiper-pagination"></div>
                </div>
            </div>
        </div>
        <!-- ***************代表客户***************** -->
        <div class="custormersBox">
            <div class="titleBox">
                <p class="firstTitle">代表客户</p>
                <p class="secTitle">On&nbsp;behalf&nbsp;of&nbsp;custormers</p>
            </div>

            <div class="custormersmsg">
                 <div class="custormerinfo" v-for="item in custormersImgs" :key="item.id">
                      <img :src="item.img" :alt="item.name">
                      <p>{{item.name}}</p>
                 </div>
            </div>
        </div>
        
</div>
</template>

<script>
    import 'swiper/dist/js/swiper'
    import 'swiper/dist/css/swiper.css'
    import Segments from '../components/Segments'
    import Swiper from "swiper"
    
    export default{
        name:"Home",
        data(){
            return{
                list: [
                    'bannerChannel_1',
                    'bannerChannel_2',
                    'bannerChannel_3'
                ],
                listIndex: 0, //默认显示第几张图片
                timer: null, //定时器
                segmentArr:[
                    {id:'1',type:'1',indexArr:['2','1'],img:require('../../src/assets/css/images/1.png'),name:'全面预算内控平台v8',secname:'实现“全面、全额、全员”的精细化预算管理',link:'productsYs'},
                    {id:'2',type:'2',indexArr:['2','2'],img:require('../../src/assets/css/images/2.png'),name:'财务核算系统',secname:'实现医共体集团化财务管理，实现一本账管理',link:'Accounting'},
                    {id:'3',type:'3',indexArr:['2','3'],img:require('../../src/assets/css/images/3.png'),name:'电子档案系统',secname:'会计档案电子化采集、整理、归档、存储、检索、应用、鉴定整体解决方案',link:'Archives'},
                    {id:'4',type:'4',indexArr:['2','4'],img:require('../../src/assets/css/images/4.png'),name:'人员经费平台',secname:'奖金从上到下逐层分配；院外劳务费自动计税、工资消息推送；多维度统计查询',link:'personnelFunds'},
                    {id:'5',type:'5',indexArr:['2','5'],img:require('../../src/assets/css/images/5.png'),name:'绩效系统',secname:'打造医院全面、科学、合理的绩效管理和评价体系。',link:'Achievements'},
                    {id:'6',type:'6',indexArr:['2','6'],img:require('../../src/assets/css/images/6.png'),name:'应付款/供应商平台',secname:'实现货物票据、付款的综合管理，结合供应商形成数据一体化闭环。',link:'Financial'},
                    {id:'7',type:'7',indexArr:['2','7'],img:require('../../src/assets/css/images/7.png'),name:'一站式票务平台',secname:'采用现代信息化技术手段实现发票的无纸化管理',link:'ticketPlatform'},
                    {id:'8',type:'8',indexArr:['2','8'],img:require('../../src/assets/css/images/8.png'),name:'网银资金池',secname:'实现资金划转和归集，提高资金利用效率',link:'Ebank'},
                ],
                swiperImgs:[
                    {id:'1',img:require('../../src/assets/css/images/slide1.png')},
                    {id:'2',img:require('../../src/assets/css/images/slide2.png')},
                    {id:'3',img:require('../../src/assets/css/images/slide3.png')}
                ],
                custormersImgs:[
                    {id:'1',img:require('../../src/assets/css/images/sanitater/1.png'),name:'浙江省卫生健康委员会'},
                    {id:'2',img:require('../../src/assets/css/images/sanitater/2.png'),name:'浙江省疾病预防控制中心'},
                    {id:'3',img:require('../../src/assets/css/images/sanitater/3.png'),name:'浙江省人民医院'},
                    {id:'4',img:require('../../src/assets/css/images/sanitater/4.png'),name:'浙江省人民医院'},
                    {id:'5',img:require('../../src/assets/css/images/sanitater/5.png'),name:'浙江大学医学院附'},
                    {id:'6',img:require('../../src/assets/css/images/sanitater/6.png'),name:'浙江省肿瘤医院'},
                    {id:'7',img:require('../../src/assets/css/images/sanitater/7.png'),name:'浙江大学医学院附属第二医院'},
                    {id:'8',img:require('../../src/assets/css/images/sanitater/8.png'),name:'浙江大学医学院附属邵逸夫医院'},
                    {id:'9',img:require('../../src/assets/css/images/sanitater/9.png'),name:'温州医科大学附属第一医院'},
                    {id:'10',img:require('../../src/assets/css/images/sanitater/10.png'),name:'浙江中医药大学附属第一医院'},
                    {id:'11',img:require('../../src/assets/css/images/sanitater/11.png'),name:'温州医科大学附属第二医院'},
                    {id:'12',img:require('../../src/assets/css/images/sanitater/12.png'),name:'浙江大学医学院附属妇产科医院'},
                    {id:'13',img:require('../../src/assets/css/images/sanitater/13.png'),name:'浙江医院'},
                    {id:'14',img:require('../../src/assets/css/images/sanitater/14.png'),name:'浙江大学医学院附属儿童医院'},
                    {id:'15',img:require('../../src/assets/css/images/sanitater/15.png'),name:'浙江中医药大学附属第三医院'},
                    {id:'16',img:require('../../src/assets/css/images/sanitater/16.png'),name:'浙江大学医学院附属口腔医院'},
                    {id:'17',img:require('../../src/assets/css/images/sanitater/17.png'),name:'浙江中医药大学附属第二医院'},
                    {id:'18',img:require('../../src/assets/css/images/sanitater/18.png'),name:'浙江省立同德医院'},
                    {id:'19',img:require('../../src/assets/css/images/sanitater/19.png'),name:'浙江省皮肤病医院'},
                    {id:'20',img:require('../../src/assets/css/images/sanitater/20.png'),name:'德清县武康健康保健集团'}
                ]
               
            }
        },
        components:{Segments},
        methods:{
            toLink(target,index){
                this.$router.push({
                    path:'/'+target,
                    query:{}
                })
                this.$emit('indexGetter',index)
                document.querySelector("#forHeader").scrollTop = 0
            },
            setTimer() {
                this.timer = setInterval(() => {
                    this.listIndex++;
                    if (this.listIndex == this.list.length) {
                        this.listIndex = 0;
                    }
                }, 5000);
            },
        },
        mounted(){
            this.setTimer(),
            setTimeout(()=>{
                new Swiper('.swiper-container', {
                    direction: 'horizontal', // 垂直切换选项
                    mousewheel: true, //滚轮
                    runCallbacksOnInit:true,
                    observer:true,//修改swiper自己或子元素时，自动初始化swiper 
                    observeParents:true,//修改swiper的父元素时，自动初始化swiper 
                    loop: true, // 循环模式选项
                    slidesPerView :3,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true, // 分页器可以点击
                    },
                    autoplay: {
                        delay: 2500,    // 自动播放间隔，单位ms
                        disableOnInteraction: false, // 值为false表示用户操作swiper之后，不会停止播放，值true停止
                        waitForTransition: false,
                    }
                })
            },300)
        }
        
    }
</script>

<style scoped>
    .bannerChannel_1{
        background-image: url(../../src/assets/css/images/home_banner.png);
    }
    .bannerChannel_2{
        background-image: url(../../src/assets/css/images/home_banner-2.png);
    }
    .bannerChannel_3{
        background-image: url(../../src/assets/css/images/home_banner-3.png);
    }
    #homeHeader{
        height: 761px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        transition:all 1s;
    }
    #homeHeader .banner{
        width: 10%;
        height: 10%;
    }
    .swiper-pagination{
        position:static !important;
        margin-top:2%;
    }  
    .imgmsgBox{
        display: flex;
        flex-direction: column;
        align-items: center;
        width:80%;
    }
    .imgBox{
        border: 1px solid #fff;
        border-radius: 50%;
        padding: 20%;
        position: relative;
        margin-bottom: 40px;
        cursor: pointer;
        background-size: 50% 50%;
        background-repeat: no-repeat;
        background-position: center center;
    }
    .imgBox[type='1']{
        background-image: url('../../src/assets/css/images/1.png');
    }
    .imgBox[type='2']{
        background-image: url('../../src/assets/css/images/2.png');
    }
    .imgBox[type='3']{
        background-image: url('../../src/assets/css/images/3.png');
    }
    .imgBox[type='4']{
        background-image: url('../../src/assets/css/images/4.png');
    }
    .imgBox[type='5']{
        background-image: url('../../src/assets/css/images/5.png');
    }
    .imgBox[type='6']{
        background-image: url('../../src/assets/css/images/6.png');
    }
    .imgBox[type='7']{
        background-image: url('../../src/assets/css/images/7.png');
    }
    .imgBox[type='8']{
        background-image: url('../../src/assets/css/images/8.png');
    }
    .imgBox:hover{
        background-color: rgba(255, 255, 255, 0.543);
    }
    
    .imgmsg{
        color:#fff;
        font-size:18px;
    }
    .imgsecmsg{
        color:rgba(255, 255, 255, 0.614);
    }
    .swiper-slide{
        height:30% !important;
    }
    .swiper-slide img{
        width:87%;
    }
    .segmentsMsg{
        display: flex;
        width: 13%;
        flex-direction: column;
        align-items: center;
        align-content: space-between;
    }
    .segmentsMsgBox{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .segmentsMsgWidthControler{
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
    .segmentsBox{
        background-image:url(../../src/assets/css/images/segments.png);
        margin-top:2px;
        height:761px;
        overflow:hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width:100%;
    }
   .segmentsBox .titleBox{
        width: 100%;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 2;
   }
   .segmentsBox .firstTitle{
        font-size: 30px;
        font-weight: 900;
   }
   .segmentsBox .secTitle{
        font-size: 16px;
        color:rgba(255, 255, 255, 0.614);
   }

   .featuresBox{
        margin-top:100px;
        height:460px;
        overflow:hidden;
   }
   .featuresBox .titleBox{
        width: 100%;
        color: #333;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 2;
    }
   .featuresBox .firstTitle{
        font-size: 30px;
        font-weight: 900;
    }
    .featuresBox .secTitle{
        font-size: 16px;
        color:rgba(59, 59, 59, 0.614);
    }


    .custormersBox{
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .custormersBox .titleBox{
        width: 100%;
        color: #333;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 2;
    }
    .custormersBox .firstTitle{
        font-size: 30px;
        font-weight: 900;
    }
    .custormersBox .secTitle{
        font-size: 16px;
        color:rgba(59, 59, 59, 0.614);
    }
      
    .swiperBox{
        width: 70%;
        margin: 0 auto;
    }
    .swiper-msg{
        background-color: #fff;
        border-radius: 4px;
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: absolute;
        bottom: -7%;
        padding:15px;
        left: 9%;
        cursor: pointer;
        height:80px;
        transition: all 0.3s;
        box-shadow: 1px 3px 6px rgb(141 141 141);
    }
    .swiper-msg:hover{
        height: 150px;
        background-color: #0080ff;
        color: #fff;
    }
    .swiper-msg:hover .swiper-msg-sectitle{
      white-space:inherit;
      -ms-text-overflow:inherit;
      text-overflow:inherit;
      overflow:inherit;
    }
    .swiper-msg-title{
        font-weight: 800;
        margin:0;
    }
    .swiper-msg-sectitle{
        width: 100%;
        white-space: nowrap;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .custormersmsg{
        width:80%;
        margin-top: 50px;
    }
    .custormerinfo{
        width:20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 206px;
        justify-content: space-evenly;
        border: 0.5px solid #d1d1d1;
        margin-left: -1px;
        margin-top: -1px;
        background-color:#fff;
        float: left;
        
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: transform;
        transition-property: transform;
    }
    .custormerinfo p{
        font-weight: 800; 
    }
    .custormerinfo:hover{
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        border:none;
        box-shadow:1px 3px 16px rgb(126 185 233)
    }
</style>