<template>
<div>
    <!-- ***************关于我们板块***************** -->
    <div id="aboutusHeader">
        <div class="coverfont">
            <p class="coverfont_title">杭州仁享软件有限公司</p>
            <p class="coverfont_sec_title">2014年成立至今，始终专注于医疗财务信息领域</p>
        </div>
    </div>
    <div class="bodyProduct">
        <!-- ***************产品介绍***************** -->
        <Introduce :title="introduceTitle" :sectitle="introducesecTitle" :msg="introductionMsg"></Introduce>
        
        <!-- ***************著作证书***************** -->
        <div class="certificateBox">
            <div class="titleBox">
                <p class="firstTitle">著作证书</p>
                <p class="secTitle">Certificate of Works</p>
            </div>
            <div class="certificateInfoBox">
                <div class="certificateInfo" v-for="(item,index) in certificateInfoArr" :key="index">
                    <div class="certificateInfoImg"><img :src="item.img"></div>
                    <span class="certificateInfoMsg">{{item.name}}</span>
                </div>

            </div>
        </div>

        <!-- ***************浙江省占有率***************** -->
        <div class="occupancyBox">
            <div class="titleBox">
                <p class="firstTitle">浙江省占有率大于80%</p>
                <p class="secTitle">The share of Zhejiang Province is more than 80%</p>
            </div>
            <div class="occupancy">
                <div class="occupancyImgBox"><img src="../assets/css/images/map.png"></div>
                <div class="occupancyMsgBox">
                    <div class="occupancyInfoBox" v-for="(item,index) in occupancyArr" :key="index">
                        <div class="occupancyTitleImg">
                            <img src="../assets/css/images/target.png">
                        </div>
                        <div class="occupancyMsg">
                            <span class="occupancyMsgTitle">{{item.title}}</span>
                            <span class="occupancyMsgInfo">{{item.msg}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>      

<script>
    import Introduce from '../components/Introduce'
    export default{
        name:"aboutUs",
        data(){
            return{
                introduceTitle:'公司与团队',
                introducesecTitle:'Company and Teaml',
                introductionMsg:[{msg:'杭州仁享软件有限公司，2014年成立于杭州，是一家专业从事医疗行业财务管理软件研发的单位。公司响应浙江省医疗单位信息化改革的新政策，始终秉持着基于事项会计理论而构建的业财税一体化平台，帮助医院搭建实时、精细、多维、可视、智能的新财务体系，助力财务数字化转型。'},
                {msg:'业务能力涵盖全面预算、财务核算、成本、绩效、电子档案、人员经费等六大领域，其中核心业务为全面预算内控管理，我们提供软件的同时也致力于为客户提供全面预算管理整体解决方案。最终实现医院的智能化、数字化、无纸化建设。'},
                {msg:'公司成员中75%技术研发人员，由全面预算研发部、协同办公研发部、支付平台研发部、票务平台研发部、大数据引擎组、网络安全组等多个研发部门组成，熟悉财务各类系统结构，熟悉财务账务处理流程及需求。并且在近五年中不断研发全面预算内控管理平台等财务资金管理平台。同时，和其他软件公司合作研发出医财云等SAAS\PASS云端产品。'}], 
            
                certificateInfoArr:[{name:'预算系统著作证书',img:require("../assets/css/images/ysxt.png")},
                                    {name:'财务凭证对接系统著作证书',img:require("../assets/css/images/cwpz.png")},
                                    {name:'绩效管理软件著作证书',img:require("../assets/css/images/jxgl.png")},
                                    {name:'更多...',img:require("../assets/css/images/gd.png")}],

                occupancyArr:[{title:'省级医院',msg:'浙医一院（三甲）、浙医二院（三甲）、温附一院（三甲），温附二院（三甲）、浙江医院（三甲）、省中医（三甲）、省儿保（三甲）、省肿瘤（三甲）、省口腔（三甲）...'},
                {title:'市县级医院',msg:'嘉兴妇保（三甲）、宁波二院（三甲）、绍兴人民（三甲）、衢州人民、温州口腔、玉环人民、建德人民、诸暨人民、宁波妇儿（三甲）、北仑人民、金华二院（三甲）、舟山医院（三甲）、丽水二院（三甲）...'},
                {title:'行政事业单位',msg:'浙江省财政厅、浙江省卫健委、浙江省疾控中心、浙江省血液中心'}]
            }
        },
        mounted(){
            
        },
        components:{Introduce}
        
    }
</script>

<style lang='scss' scoped>
    .occupancyMsgBox{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 400px;
        width: 40%;
    }
    .occupancyMsgTitle{
        font-size: 18px;
        font-weight: 600;
        color: #1385EC;
    }
    .occupancyMsgInfo{
        font-size:14px;
    }
    .occupancyMsg{
        display: flex;
        flex-direction: column;
        width: 80%;
        margin-left: 10px;
    }
    .occupancyInfoBox{
        display: flex;
        flex-direction: row;
    }
    .occupancyBox{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top:100px;
    }
    .occupancy{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 70%;
        justify-content: space-between;
    }
    .occupancyImgBox img{
        height: 600px;
    }
    .occupancyTitleImg img{
        height:20px;
    }
    .certificateInfoImg img{
        height:372px;
    }
    .titleBox{
        margin-bottom:30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .certificateBox{
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-image: url(../assets/css/images/certificatebg.png);
        width: 100%;
        height: 676px;
        justify-content: center;
    }
    .certificateBox .titleBox{
        width: 100%;
        color: #333;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 2;
    }
    .certificateBox .firstTitle{
        font-size: 30px;
        font-weight: 900;
    }
    .certificateBox .secTitle{
        font-size: 16px;
        color:rgba(59, 59, 59, 0.614);
    }
    .certificateInfo{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .certificateInfoBox{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 67%;
    }
    .certificateInfoMsg{
        font-size: 16px;
        font-weight: 700;
        margin-top:10px;
    }
</style>