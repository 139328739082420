<template>
    <div class="introductionBox">
        <div class="titleBox">
            <p class="firstTitle" v-html="title"></p>
            <p class="secTitle" v-html="sectitle"></p>
        </div>

        <div class="introductionMsg" v-for="(item,index) in msg" :key="index"><p>{{item.msg}}</p></div>
    </div>
</template>  

<script>
    export default{
        name:'Introduce',
        data(){
            return{}
        },
        props:['title','sectitle','msg']

    }
</script>

<style>
    .introductionBox{
        margin-top: 100px;
        width:67%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .introductionBox .titleBox{
        width: 100%;
        color: #333;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 2;
    }
    .introductionBox .firstTitle{
        font-size: 30px;
        font-weight: 900;
    }
    .introductionBox .secTitle{
        font-size: 16px;
        color:rgba(59, 59, 59, 0.614);
    }
    .introductionMsg{
        display:inline-block;
        text-indent:2.5em;
    }
    .introductionMsg p{
        font-size: 17px;
        color: #565656;
    }
</style>