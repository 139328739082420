<template>
    <div class="featuresBox">
        <div class="titleBox">
            <p class="firstTitle" v-html="title"></p>
            <p class="secTitle" v-html="sectitle"></p>
        </div>
        <FeaturesInfo v-for="(item,index) in featuresInfoArr" :key="index" :features="item"></FeaturesInfo> 
    </div>
</template>

<script>
    import FeaturesInfo from '../FeaturesInfo'   
    export default{
        name:'Features',
        data(){
            return{
                featuresInfoArr:this.getNewArr(this.getNoRepeatArray(this.features,'group'),this.features,'group')
            }
        },
        methods:{
            getNoRepeatArray(jsonArr, fieldName){
                let newArr = [];
                jsonArr.forEach(item=>{
                if(newArr.indexOf(item[fieldName])==-1) {
                    newArr.push(item[fieldName])
                }
                })
                return newArr;
            },
            getNewArr(arr, jsonArr, fieldName) {
                let newArr = [];
                arr.forEach(item=>{newArr.push(this.getmaparrList(jsonArr,fieldName,item))})
                return newArr;
            },
            getmaparrList(arrlist, vname, value, flag = true) {
                return arrlist.filter((item) => flag ? (item[vname] == value) : (item[vname] != value));
            }
        },
        props:['title','sectitle','features'],
        components:{FeaturesInfo},
        mounted(){
            this.getNewArr(this.getNoRepeatArray(this.features,'group'),this.features,'group')
        }
    }
</script>

<style>

    .featuresTitle{
        font-size:16px;
        font-weight: 800;
    }
    .featuresBox{
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width:100%;
    }
    .featuresBox .titleBox{
        width: 100%;
        color: #333;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 2;
    }
    .firstTitle{
        font-size: 30px;
        font-weight: 900;
    }
    .secTitle{
        font-size: 16px;
        color:rgba(59, 59, 59, 0.614);
    }
    .featuresMsgBox{
        width: 69%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .featuresMsg img{
        width:46px;
    }
    .featuresMsg img,.featuresMsg p{
        margin-bottom:10px;
    }
    .featuresMsg{
        width: 30.7%;
        height: 230px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 20px 20px 20px 20px;
        box-shadow: 0px 0px 11px #92c9ff;
        transition: all 0.5s;
    }
    .featuresMsg:hover{
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        box-shadow: 0px 0px 20px #92c9ff;
    }
</style>