<template>
        <!-- ***************导航板块***************** -->
        <div class="headerbox" :class="[headerShow?'headerboxshow':'headerboxhide']">
            <img class="logo" src="../../assets/css/images/logo.png" alt="">
                <div class="menu_box">
                    <div class="menu_list" :class="[this.activeIndex == '1' ? 'menu_list_active':'menu_list_noactive']" @click="toLink('Home')"><span>首页</span></div>
                    <div class="menu_list sonlist" :class="[this.activeIndex == '2' ? 'menu_list_active':'menu_list_noactive']">
                        <span>产品中心</span> 
                        <div class="productlist">
                            <span @click="toLink('productsYs')">全面预算内控平台v8</span>
                            <span @click="toLink('Financial')">应付款/供应商平台</span>
                            <span @click="toLink('ticketPlatform')">票务平台</span>
                            <span @click="toLink('Ebank')">网银资金池</span>
                            <span @click="toLink('Accounting')">财务会计核算系统</span>
                            <span @click="toLink('Archives')">电子档案系统</span>
                            <span @click="toLink('personnelFunds')">人员经费平台</span>
                            <span @click="toLink('Achievements')">绩效方案/绩效系统</span>
                        </div>
                    </div>
                    <div class="menu_list" :class="[this.activeIndex == '3' ? 'menu_list_active':'menu_list_noactive']" @click="toLink('Solution')"><span>解决方案</span></div>
                    <div class="menu_list" :class="[this.activeIndex == '4' ? 'menu_list_active':'menu_list_noactive']" @click="toLink('aboutUs')"><span>关于我们</span></div>
                </div>
                <div class="line"></div>
        </div>  
</template>

<script>
    export default{
        name:"Header",
        data(){
            return{
                activeIndex:this.$route.meta.nav[0]
            }
        },
        methods:{
            toLink(target,event){
                this.$router.push({
                    path:'/'+target,
                    query:{}
                })
                this.activeIndex = this.$route.meta.nav[0]
                document.querySelector("#forHeader").scrollTop = 0
            },
            navChild(){},
            handleSelect(key, keyPath){},
        },
        watch:{
            indexParam:{
                handler(newVal){
                    this.activeIndex = newVal[0]
                }
	        }
        },
        props:['indexParam','headerShow']
    }
</script>

<style>
    .line{
        width:15%;
    }
    .productlist{
        background-color: #fff;
        color: #333;
        display: none;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 60px;
        z-index: 999;
        font-size: 15px;
        width: 174px;
        padding: 10px 0 10px 0;
        border-radius:4px;
    }
    .productlist span{
        padding: 0 10px 0 10px;
        border-radius: 4px;
        width: 90%;
    }
    .productlist span:hover{
        color:#0080ff;
        background-color:rgb(232, 232, 232);

    }
    .sonlist{
        background-image: url(../../assets/css/images/menudown.png);
        background-position:100%;
        background-repeat: no-repeat;
        background-size:17px 17px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .sonlist:hover{
        background-image: url(../../assets/css/images/menuup.png);
    }
    .sonlist:hover .productlist{
        display: flex;
    }
    .menu_box{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 29%;
        font-size: 16px;
    }
    .menu_list{
        width: 21%;
        text-align: center;
        height: 60px;
        line-height: 60px;
        border-bottom:2px solid rgba(255, 255, 255, 0);
    }
    .menu_list_noactive{
        border-bottom:2px solid rgba(255, 255, 255, 0);
    }
    .menu_list_active{
        border-bottom:2px solid #fff; 
    }
    .menu_list:hover{
        border-bottom:2px solid #fff;

    }
    .coverfontWidthControler{
        width: 67%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    #bannerbox{
        height:550px;
    }
    #productHeader{
        height: 550px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        background-color: #0c004f;
    }
    .bannerImg{
        width:500px;
    }
    #productHeader .coverfont_sec_title{
        margin-top:20px;
        font-size:18px;
        font-weight: 500;
        width:76%;
    }
    #financialHeader{
        height: 550px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        background-color: #0c004f;
    }
    #financialHeader .coverfont_sec_title{
        margin-top:20px;
        font-size:22px;
        font-weight: 500;
        width:76%;
    }
    #aboutusHeader{
        background-image: url(../../assets/css/images/ywtd.png);
        height: 761px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    #aboutusHeader .bannerImg{
        width: 26%;
    }
    #aboutusHeader .coverfont{
        z-index: 2;
        color: #fff;
        width: 60%;
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-left:12%;
    }
    #aboutusHeader .coverfont_sec_title{
        margin-top:20px;
        font-size:30px;
        font-weight: 500;
        width:76%;
    }
    .coverfontmsg{
        display: flex;
        flex-direction: column;
        width: 72%;
        font-size:16px;
    }
    .coverfontmsg span{
        width:150px;
        height:40px;
        background-color: rgb(14,40,126);
        text-align: center;
        line-height:40px;
        margin: 0 10px 0 0;
        transition:all 0.3s;
    }
    .coverfontmsg span:hover{
        transform: scale(1.1);
    }
    .coverfontmsgup{
        margin-top:40px;
    }
    .coverfontmsgup,.coverfontmsgdown{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom:10px;
    }

    .active{
        border-bottom:5px solid #fff;
    }
    .logo{
        height:46px;
        float:left;
    }
    .phone{
        height: 40px;
        padding-top: 10px;
        position: absolute;
        right: 20px;
        top: 0;
    }
    .headerbox{
        background-color: rgb(14 12 36);
        color: #fff;
        height: 60px;
        padding-left: 20px;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .headerboxshow{

        transition: all 0.5s;
        top:0;
    }
    .headerboxhide{

        transition: all 0.5s;
        top: -60px;
    }
    .navbar{
        list-style: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        float:left;
        margin-left: 15%;
        height: 60px;
        line-height: 60px;
        font-size:16px;
        
    }
    .navbar li{
        margin: 0 20px 0 20px;
        cursor:pointer;
    }
    .coverfont{
        z-index: 2;
        color: #fff;
        width: 50%;
    }
    .coverfont_title{
        font-size: 54px;
        font-weight: 800;
        letter-spacing: 5px;
        width:90%;
    }
    .coverfont_sec_title{
        width: 100%;
        font-size: 20px;
        letter-spacing: 3px;
    }
    .coverfont_btn{
        border: 2px solid #fff;
        border-radius: 50px;
        width: 200px;
        height: 46px;
        line-height: 46px;
        text-align: center;
        font-size: 16px;
        margin-top: 120px;
        cursor:pointer;
        transition:all 1s;
    }
    .coverfont_btn:hover{
        background-color: rgba(255, 255, 255, 0.54);
        transform: scale(1.2);
    }
    .headerbox li{
        margin-left:50px;
    }

</style>