<template>
<div>
    <!-- ***************产品中心应付款/供应商平台板块***************** -->
    <div id="financialHeader">
        <div class="coverfontWidthControler">
            <div class="coverfont">
                <p class="coverfont_title">应付款/供应商平台</p>
                <p class="coverfont_sec_title">实现货物票据的综合管理系统，结合供应商形成数据一体化闭环</p>
            </div>
            <img src="../assets/css/images/yfkbanner.png" class="bannerImg">
        </div>
    </div>
    
    <div class="bodyProduct">
        <!-- ***************产品介绍***************** -->
        <Introduce :title="introduceTitle" :sectitle="introducesecTitle" :msg="introductionMsg"></Introduce>
        <!-- ***************产品特点***************** -->
        <Features :title="featuresTitle" :sectitle="featuressecTitle" :features="features"></Features>
        <productImg :productImgMsgArr="productImgMsgArr" :productImgLink="productImgLink"></productImg>
        <!-- ***************供应商发票管理***************** -->
        <div class="productImgBox2">
            <div class="productImgBox2WidthControler">
                <div class="productImgBox2Msg">
                    <p class="productImgBox2MsgTitle">供应商发票管理</p>
                    <p class="productImgBox2MsgInfo">供应商应付款账单管理支持供应商多途径上传发票、确认应付款单，随时查看应付款单审核、付款进度。供应商一键查询开出发票的支付、未支付状态。</p>
                </div>
                <img src="../assets/css/images/ykfgroup.png">
            </div>
        </div>

        <!-- ***************供应链金融***************** -->
        <titleAndPic :title="titleAndPic_title" :sectitle="titleAndPic_sectitle" :img="titleAndPic_pic"></titleAndPic>
    </div>
</div>
</template>      

<script>
    import Introduce from '../components/Introduce'
    import Features from '../components/Features'
    import titleAndPic from '../components/titleAndPic'
    import productImg from '../components/productImg'
    export default{
        name:"Financial",
        data(){
            return{
                introduceTitle:'产品介绍',
                introducesecTitle:'Product&nbsp;introduction',
                introductionMsg:[{msg:'应付款平台是一款结合预算、票据池数据对接，以实现货物票据验真、验重、供应商发票验证、上传、授权开票、个人应付款、对公应付款功能的综合管理系统；建立供应商平台，使供应商支持应付款单的发票上传、验证、授权开票以及应付款支付状态查询，形成数据一体化闭环。'}],

                featuresTitle:'产品特点',
                featuressecTitle:'Product&nbsp;features',
                features:[
                    {id:'1',title:'互联互通 汇总管控',msg:'应付款池汇总展示所有对公、对私应付款数据，统一支付入口，提高管控力度，降低管控难度。',img:require('../assets/css/images/hlht.png'),group:'1'},
                    {id:'2',title:'供应商平台',msg:'建立供应商平台，支持应付款单的发票上传、验证、授权开票以及应付款支付状态查询，形成数据一体化闭环。',img:require('../assets/css/images/gyspt.png'),group:'1'},
                    {id:'3',title:'移动审核',msg:'支持手机端审核，随时随地进行流程处理，摆脱地域限制，提高工作效率。',img:require('../assets/css/images/ydsh.png'),group:'1'},
                    {id:'4',title:'供应商应收款管理',msg:'支持供应商多途径上传发票、确认应付款单，随时查看付款进度。',img:require('../assets/css/images/gyssk.png'),group:'2'},
                    {id:'5',title:'供应商发票管理',msg:'供应商查询自己开出发票的支付、未支付状态',img:require('../assets/css/images/gysfp.png'),group:'2'},
                    {id:'6',title:'医疗供应链金融',msg:'院方可获得额外利息收益，实现无风险金融业务。最终实现三方共赢。',img:require('../assets/css/images/ylgll.png'),group:'2'}
                ],

                titleAndPic_title:'供应链金融',
                titleAndPic_sectitle:'Supply Chain Finance',
                titleAndPic_pic:require('../assets/css/images/gyljr.png'),

                productImgMsgArr:[{title:'应付款单展示',info:[{name:'同步获取业务系统应付款数据，如收款方、账户信息、应付金额等。'},{name:'金额验证'},{name:'多种支付方式'},{name:'国库支付'}]}],
                productImgLink:require('../assets/css/images/yfkzs.png'),
            }
        },
        mounted(){},
        components:{Introduce,Features,titleAndPic,productImg}
        
    }
</script>

<style lang='scss' scoped>
    .productImgBox2{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width:100%;
        height:600px;
        margin-top:100px;
        background-image:url(../assets/css/images/certificatebg.png);
    }
    .productImgBox2WidthControler{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width:80%;
    }
    .productImgBox2 img{
        width:60%;
        transition: all 0.5s;
    }
    .productImgBox2 img:hover{
        transform: scale(1.1);
    }
    .productImgBox2Msg{
        width:33%;
    }
    .productImgBox2MsgTitle{
        font-size:30px;
    }
    .productImgBox2MsgInfo{
        font-size:16px;
        color: #767676;
        display: block;
        text-indent:2em;
    }
    .bodyProduct{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .titleBox{
        margin-bottom:30px;
    }
</style>