<template>
    <div>
    <!-- ***************产品中心电子档案系统板块***************** -->
    <div id="financialHeader">
        <div class="coverfontWidthControler">
            <div class="coverfont">
                <p class="coverfont_title">电子档案系统</p>
                <p class="coverfont_sec_title">实现会计凭证、账簿、报表和其他会计资料的电子化全流程管理</p>
            </div>
            <img src="../assets/css/images/dzdabanner.png" class="bannerImg">
        </div>
    </div>
    <div class="bodyProduct">
        <!-- ***************产品介绍***************** -->
        <Introduce :title="introduceTitle" :sectitle="introducesecTitle" :msg="introductionMsg"></Introduce>
        

        <!-- ***************产品特点***************** -->
        <Features :title="featuresTitle" :sectitle="featuressecTitle" :features="features"></Features>

        <!-- <div class="productImgBox">
            <div class="productImgBoxWidthControler">
                <div class="productImg">
                    <img src="../assets/css/images/xsdzlc.png" alt="">
                </div>
                <div class="productImgMsg">
                    <p class="productImgTitle">打通档案「采集-管理-利用」全流程</p>
                    <p class="productImgInfo"><img src="../assets/css/images/icon1.png">多样化采集方式</p>
                    <p class="productImgInfo"><img src="../assets/css/images/icon1.png">数字加密，防篡改</p>
                    <p class="productImgInfo"><img src="../assets/css/images/icon1.png">归档、调阅线上处理</p>
                </div>
            </div>
        </div> -->
        <productImg :productImgMsgArr="productImgMsgArr" :productImgLink="productImgLink"></productImg>
        <!-- ***************产品架构***************** -->
        <titleAndPic :title="titleAndPic_title" :sectitle="titleAndPic_sectitle" :img="titleAndPic_pic" :msgTitle="titleAndPic_msgtitle"></titleAndPic>
    </div>
</div>
</template>      

<script>
    import Introduce from '../components/Introduce'
    import Features from '../components/Features'
    import titleAndPic from '../components/titleAndPic'
    import productImg from '../components/productImg'
    export default{
        name:"Archives",
        data(){
            return{
                introduceTitle:'产品介绍',
                introducesecTitle:'Product&nbsp;introduction',
                introductionMsg:[{msg:'2020年3月31日，财政部、国家档案局联合发布《关于规范电子会计凭证报销入账归档的通知》，重点围绕电子会计凭证报销入账归档的合法性、规范性提出了具体要求。电子财务档案管理系统，为医院财务档案采集、生成、匹配、归档、借阅、销毁全流程的数字化管理提供支撑，实现会计凭证、账簿、报表等各类档案资料的全覆盖，以及医院财务档案采集、档案管理、档案借阅、库存管理的全流程管控。'},],

                featuresTitle:'产品特点',
                featuressecTitle:'Product&nbsp;features',
                features:[
                    {id:'1',title:'档案整理规范化',msg:'将归档会计资料进行分类，给定分类代号，并对归档文件划定保管期限系统还提供档案号、案卷号/卷内目录以及盒号的自定义配置，方便借阅及整理。',img:require('../assets/css/images/dazl.png'),group:'1'},
                    {id:'2',title:'档案管理智能化',msg:'支持电子档案存储格式的转换，并追加电子签章。根据期限、密级等属性自动对到期档案鉴定。支持档案销毁申请、审批、建立销毁标记、监销、删除等操作，自动生成销毁清册并归档。',img:require('../assets/css/images/dzgl.png'),group:'1'},
                    {id:'3',title:'档案权限自定义化',msg:'电子档案权限按照可按照用户、档案状态、档案全宗和档案类型维度设置权限。提高电子档案的安全性',img:require('../assets/css/images/daqx.png'),group:'2'},
                    {id:'4',title:'传统载体档案辅助管理全面化',msg:'对传统载体档案提出存放位置建议，实现对实体档案所在库房位置的管理',img:require('../assets/css/images/ctzt.png'),group:'2'},
                    {id:'5',title:'纸电并行归档',msg:'支持与仁享业务系统对接的电子化采集方式，也支持高拍仪等纸质件的扫描上传，适用于医院无纸化改革的现行阶段',img:require('../assets/css/images/zdbx.png'),group:'2'},
                    {id:'6',title:'格式转化&电子签章',msg:'将word、excel、图片等格式转化成符合归档要求的版式文件（PDF或OFD）长期保存，并追加电子签章。',img:require('../assets/css/images/gsh.png'),group:'3'},
                    {id:'7',title:'四性检测',msg:'真实性检测、完整性检测、可用性检测、安全性检测',img:require('../assets/css/images/sxjc.png'),group:'3'},
                    {id:'8',title:'电子票据入账凭证',msg:'统一从官方渠道获取单位各类开具凭证版式文件/结构化数据，自动生成入账凭证',img:require('../assets/css/images/dzpj.png'),group:'3'},
                ],
                productImgMsgArr:[{title:'打通档案「采集-管理-利用」全流程',info:[{name:'多样化采集方式'},{name:'数字加密，防篡改'},{name:'归档、调阅线上处理'}]}],
                productImgLink:require('../assets/css/images/xsdzlc.png'),
                titleAndPic_title:'产品架构',
                titleAndPic_sectitle:'Product features',
                titleAndPic_msgtitle:'系统基于安全性、实用性和开放性原则，搭建了企业财务档案数字化管理的平台。',
                titleAndPic_pic:require('../assets/css/images/cpjg.png')
                
            }
        },
        mounted(){
            
        },
        components:{Introduce,Features,titleAndPic,productImg}
        
    }
</script>

<style lang='scss' scoped>
    .bodyProduct{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .productImgInfo{
        font-size:19px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .productImgInfo img{
        height:19px;
        margin-right:5px;
    }
    .productImgMsg{
        color: #fff;
    }
    .productImgTitle{
        font-size: 34px;
        font-weight: 800;
    }
    .productImgBox{
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        width: 100%;
        background-image: url(../assets/css/images/achievements.png);
        background-repeat: no-repeat;
        background-size: cover;
        align-items: center;
    }
    .productImgBoxWidthControler{
        width:80%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
    .productImg{
        width:45%;
    }
    .productImg img{
        width:100%;
    }
    .titleBox{
        margin-bottom:30px;
    }
</style>