<template>
    <div class="productImgBox">
        <div class="productBoxWidthControler">
            <div class="productImg">
                <img :src="productImgLink" alt="">
            </div>
            <div class="productImgMsg">
                <div v-for="(item,index) in productImgMsgArr" :key="index">
                    <p class="productImgTitle">{{item.title}}</p>
                    <p class="productImgInfo" v-for="(item2,index) in item.info" :key="index"><img src="../../assets/css/images/icon1.png">{{item2.name}}</p>
                </div>
            </div>
                
            </div>
        </div>
</template>

<script>
    export default{
        name:"productImg",
        data(){
            return{

            }
        },
        props:['productImgMsgArr','productImgLink']
        
    }
</script>

<style>
    .productImgInfo{
        font-size:19px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .productImgInfo img{
        height:19px;
        margin-right:5px;
    }
    .productImgMsg{
        color: #fff;
        width:40%;
    }
    .productImgTitle{
        font-size: 34px;
        font-weight: 800;
    }
    .productImgBox{
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        width: 100%;
        background-image: url(../../assets/css/images/achievements.png);
        background-repeat: no-repeat;
        background-size: cover;
        align-items: center;
        height: 475px;
        justify-content: center;
        margin-bottom:74px;
    }
    .productBoxWidthControler{
        width: 80%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-around;
        margin-top:9%;
    }
    .productImg{
        width:45%;
    }
    .productImg img{
        width:100%;
    }
</style>