<template>
<div>
    <!-- ***************产品中心人员经费平台板块***************** -->
    <div id="financialHeader">
        <div class="coverfontWidthControler">
            <div class="coverfont">
                <p class="coverfont_title">人员经费平台</p>
                <p class="coverfont_sec_title">实现科室奖金的二级金额分配机制，医院职工手机端
                    薪酬查询，提高医院人员经费信息化管理水平</p>
            </div>
            <img src="../assets/css/images/ryjfbanner.png" class="bannerImg">
        </div>
    </div>
    <div class="bodyProduct">
        <!-- ***************产品介绍***************** -->
        <Introduce :title="introduceTitle" :sectitle="introducesecTitle" :msg="introductionMsg"></Introduce>
        
        <!-- ***************人员经费平台总览图***************** -->
        <!-- <div class="product2Box">
            <div class="titleBox">
                <p class="firstTitle">人员经费平台总览图</p>
                <p class="secTitle">Overview of personnel funds platform</p>
            </div>

            <div class="product2Msg">
                <div class="product2MsgImg"><img src="../assets/css/images/ryjfzlt.png"></div>
            </div>
        </div> -->

        <titleAndPic :title="titleAndPic_title" :sectitle="titleAndPic_sectitle" :img="titleAndPic_pic" :msgTitle="titleAndPic_msgtitle"></titleAndPic>
        <!-- ***************产品特点***************** -->
        <Features :title="featuresTitle" :sectitle="featuressecTitle" :features="features"></Features>

        
        <!-- ***************掌薪宝***************** -->
        <div class="productImgBox2">
            <div class="productImgBox2WidthControler">
                <div class="productImgBox2Msg">
                    <p class="productImgBox2MsgTitle">掌薪宝</p>
                    <p class="productImgBox2MsgInfo">医院职工可通过钉钉随时随地的进行各类薪酬的查询及统计，同时医院财务及相关部门可通过系统进行各种维度的薪酬数据统计分析。</p>
                </div>
                <img src="../assets/css/images/zxb.png">
            </div>
        </div>

        <!-- ***************不同经费分配场景***************** -->
        <!-- <div class="product2Box">
            <div class="titleBox">
                <p class="firstTitle">不同经费分配场景</p>
                <p class="secTitle">Different fund allocation scenarios</p>
            </div>

            <div class="product2Msg">
                <div class="product2MsgImg"><img src="../assets/css/images/fpcj.png"></div>
            </div>
        </div> -->
        <titleAndPic :title="titleAndPic_title2" :sectitle="titleAndPic_sectitle2" :img="titleAndPic_pic2" :msgTitle="titleAndPic_msgtitle2"></titleAndPic>

        <!-- ***************统计分析***************** -->
        <div class="productImgBox3">
            <div class="productImgBox3WidthControler">
                <img src="../assets/css/images/tjfx.png">
                <div class="productImgBox3Msg">
                    <p class="productImgBox3MsgTitle">统计分析</p>
                    <p class="productImgBox3MsgInfo">系统支持按照人员类型、科室、人员类别、学历、职称等维度进行统计</p>
                    <p class="productImgBox3MsgInfo">收入明细表能够统计到具体的工资组成明细，奖金明细等</p>
                </div>
                
            </div>
        </div>
    </div>
</div>
</template>      

<script>
    import Introduce from '../components/Introduce'
    import Features from '../components/Features'
    import titleAndPic from '../components/titleAndPic'
    export default{
        name:"personnelFunds",
        data(){
            return{
                introduceTitle:'产品介绍',
                introducesecTitle:'Product&nbsp;introduction',
                introductionMsg:[{msg:'近年来，随着医疗改革的深入，医院要改革创新、加强管理、提高医疗服务质量，需要建立一套合理的分配机制，既要将奖金分配权责下放科室，又要集中监管科室以及个人的奖金分配状况，以持续激发职工的工作积极性。'},
                {msg:'一站式人员经费平台不仅实现了医院分配到科室及科室负责人，科室分配到个人的二级分配机制，并且实现了分配结果反馈到医院统一审核监管的目的，同时系统有一套完整的权限管理体系，确保了信息的私密性。促进了医院管理的规范化，使奖金分配情况得到有效的控制和监管，从而提高医院内部奖金分配的管理水平。'},
                {msg:'“掌薪宝”是将医院所发的各类薪酬及报销通过系统一键推送至医院职工，方便职工及时了解自身的薪资发放情况。医院职工可通过钉钉随时随地的进行各类薪酬的查询及统计，同时医院财务及相关部门可通过系统进行各种维度的薪酬数据统计分析。'},],

                featuresTitle:'产品特点',
                featuressecTitle:'Product&nbsp;features',
                features:[
                    {id:'1',title:'多类型分配',msg:'将由财务下发待分配总额，分配负责人配置至人的分配形式做成一个集成平台，其中包括科室奖金分配、劳务费分配、夜班费分配等',img:require('../assets/css/images/ctzt.png'),group:'1'},
                    {id:'2',title:'自定义分配时间',msg:'财务在下发待分配总额时自定义分配时间段，超过这个分配时间系统将自动关闭分配按钮',img:require('../assets/css/images/zdysj.png'),group:'1'},
                    {id:'3',title:'自定义分配负责人',msg:'支持分配负责人自行转移分配权，分配权限更灵活',img:require('../assets/css/images/zdyfpfzr.png'),group:'2'},
                    {id:'4',title:'院外人员自动算税',msg:'对传统载体档案提出存放位置建议，实现对实体档案所在库房位置的管理',img:require('../assets/css/images/ywry.png'),group:'2'},
                    {id:'5',title:'统计分析',msg:'支持多维度进行统计分析，收入明细表能够统计到具体的工资组成明细，奖金明细等',img:require('../assets/css/images/jyzkfx.png'),group:'2'}
                ],
                titleAndPic_title:'人员经费平台总览图',
                titleAndPic_sectitle:'Overview of personnel funds platform',
                titleAndPic_msgtitle:'',
                titleAndPic_pic:require('../assets/css/images/ryjfzlt.png'),

                titleAndPic_title2:'不同经费分配场景',
                titleAndPic_sectitle2:'Different fund allocation scenarios',
                titleAndPic_msgtitle2:'',
                titleAndPic_pic2:require('../assets/css/images/fpcj.png')
                
            }
        },
        mounted(){
            
        },
        components:{Introduce,Features,titleAndPic}
        
    }
</script>

<style lang='scss' scoped>
    .product2Msg{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .product2MsgImg{
        display: flex;
        justify-content: center;
    }
    .product2MsgImg img{
        width:60%;
    }
    .product2Box{
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .product2Box .titleBox{
        width: 100%;
        color: #333;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 2;
    }
    .product2Box .firstTitle{
        font-size: 30px;
        font-weight: 900;
    }
    .product2Box .secTitle{
        font-size: 16px;
        color:rgba(59, 59, 59, 0.614);
    }
    .bodyProduct{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .productImgInfo{
        font-size:19px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .productImgInfo img{
        height:19px;
        margin-right:5px;
    }
    .productImgBox2{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width:100%;
        height:600px;
        margin-top:100px;
        background-image:url(../assets/css/images/certificatebg.png);
    }
    .productImgBox2WidthControler{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 80%;
    }
    .productImgBox2 img{
        height:500px;
        transition: all 0.5s;
    }
    .productImgBox2 img:hover{
        transform: scale(1.1);
    }
    .productImgBox2Msg{
        width:33%;
    }
    .productImgBox2MsgTitle{
        font-size:30px;
    }
    .productImgBox2MsgInfo{
        font-size:16px;
        color: #767676;
        display: block;
        text-indent:2.5em;
    }

    .productImgBox3{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width:100%;
        height:600px;
        margin-top:100px;
        background-color:rgb(244,244,244)
    }
    .productImgBox3WidthControler{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 59%;
    }
    .productImgBox3 img{
        width:60%;
        transition: all 0.5s;
    }
    .productImgBox3 img:hover{
        transform: scale(1.1);
    }
    .productImgBox3Msg{
        width:33%;
    }
    .productImgBox3MsgTitle{
        font-size:30px;
    }
    .productImgBox3MsgInfo{
        font-size:20px;
        color: #767676;
    }
    .productImgMsg{
        position: absolute;
        right: 33%;
        top: 23%;
        color: #fff;
    }
    .productImgTitle{
        font-size: 34px;
        font-weight: 800;
    }
    .titleBox{
        margin-bottom:30px;
    }
</style>