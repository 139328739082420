<template>
<div>
    <!-- ***************产品中心一站式票务平台板块***************** -->
    <div id="financialHeader">
        <div class="coverfontWidthControler">
            <div class="coverfont">
                <p class="coverfont_title">一站式票务平台</p>
                <p class="coverfont_sec_title">采用现代信息化技术手段实现发票的无纸化管理</p>
            </div>
            <img src="../assets/css/images/yzspwpt.png" class="bannerImg">
        </div>
    </div>
    <div class="bodyProduct">
        <!-- ***************产品介绍***************** -->
        <Introduce :title="introduceTitle" :sectitle="introducesecTitle" :msg="introductionMsg"></Introduce>
        

        <!-- ***************产品特点***************** -->
        <Features :title="featuresTitle" :sectitle="featuressecTitle" :features="features"></Features>

        <productImg :productImgMsgArr="productImgMsgArr" :productImgLink="productImgLink"></productImg>
        <!-- <div class="productImgBox">
            <div class="productImgMsg">
                <p class="productImgTitle">进项</p>
                <p class="productImgInfo"><img src="../assets/css/images/icon1.png">覆盖多票种发票识别、验真查重、抵扣认证等，规避发票风险，进项发票状态实时监控</p>

                <p class="productImgTitle">销项</p>
                <p class="productImgInfo"><img src="../assets/css/images/icon1.png">发票状态实时监控，业务/财务系统无缝对接，开票系统对接</p>
            </div>
        </div> -->

    </div>
</div>
</template>      

<script>
    import Introduce from '../components/Introduce'
    import Features from '../components/Features'
    import productImg from '../components/productImg'

    export default{
        name:"ticketPlatform",
        data(){
            return{
                introduceTitle:'产品介绍',
                introducesecTitle:'Product&nbsp;introduction',
                introductionMsg:[{msg:'一站式票务平台是采用现代信息化技术手段实现发票的无纸化管理，通过财政厅（浙里办票）将医院的票据同步到本地存储，（包括：税务票据、12306票据、航空票据、财政非税票据、银行回单...），形成票据池，有效解决报销、记账、归档流程中出现假发票、违规重复报销、恶意冲红等财务风险。同时财政厅票据也代表着安全、完整等特性。'}],

                featuresTitle:'产品特点',
                featuressecTitle:'Product&nbsp;features',
                features:[
                    {id:'1',title:'票据来源安全、完整',msg:'开通医院与财政厅的票据通道，定时进行数据汇总采集，形成多票种票据池；同步的增值税电子发票文件均带有“浙里办票”电子签章，证明发票真实有效。',img:require('../assets/css/images/gysfp.png'),group:'1'},
                    {id:'2',title:'国家标准OFD格式',msg:'国税总局发布公告中提到："通过增值税电子发票公共服务平台开具的增值税电子普通发票，都是采用了符合国家统一标准的OFD格式。"',img:require('../assets/css/images/ofd.png'),group:'1'},
                    {id:'3',title:'进销项发票集中管理',msg:'支持医院抬头税号下的全部增值税发票信息查询，发票状态查询、支付状态查询。',img:require('../assets/css/images/jxxfp.png'),group:'1'},
                    {id:'4',title:'发票拍照识别',msg:'在填写报销单时通过手机拍照或通过高拍仪录入供应商发票，能对票面信息识别存储，同时与票据池发票信息进行核验，达到发票验真、防重复报销的目的。',img:require('../assets/css/images/fppz.png'),group:'2'},
                    {id:'5',title:'扫码查验',msg:'报销单据归档前，支持财务对报销人上交的纸质单据和发票进行查验。',img:require('../assets/css/images/smjc.png'),group:'2'},
                    {id:'6',title:'对接发票电子档案',msg:'全量进销项发票自动 、智能采集及关联查询调阅。',img:require('../assets/css/images/zdscpz.png'),group:'2'}
                ],
                productImgMsgArr:[{title:'进项',info:[{name:'覆盖多票种发票识别、验真查重、抵扣认证等，规避发票风险，进项发票状态实时监控'}]},
                {title:'销项',info:[{name:'发票状态实时监控，业务/财务系统无缝对接，开票系统对接'}]}],
                productImgLink:require('../assets/css/images/pwpt.png'),
            }
        },
        mounted(){
            
        },
        components:{Introduce,Features,productImg}
        
    }
</script>

<style lang='scss' scoped>
    .bodyProduct{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .titleBox{
        margin-bottom:30px;
    }
</style>