<template>
    <div class="product2Box">
        <div class="titleBox">
            <p class="firstTitle">{{title}}</p>
            <p class="secTitle">{{sectitle}}</p>
        </div>

        <div class="product2Msg">
            <div class="product2MsgTitle">{{msgtitle}}</div>
            <div class="product2MsgImg"><img :src="img"></div>
        </div>
    </div>
</template>   

<script>
    export default{
        name:'titleAndPic',
        data(){
            return{

            }
        },
        props:['title','sectitle','img','msgtitle']

    }
</script>

<style>
    .product2MsgTitle{
        font-size:18px;
    }
    .product2Msg{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .product2MsgImg{
        display: flex;
        justify-content: center;
    }
    .product2MsgImg img{
        width:60%;
        transition: all 0.5s;
    }
    .product2MsgImg img:hover{
        transform: scale(1.1);
    }
    .product2Box{
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .product2Box .titleBox{
        width: 100%;
        color: #333;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 2;
    }
    .product2Box .firstTitle{
        font-size: 30px;
        font-weight: 900;
    }
    .product2Box .secTitle{
        font-size: 16px;
        color:rgba(59, 59, 59, 0.614);
    }
</style>