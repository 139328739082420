<template>
    <div class="segmentsBox">
        <div class="titleBox">
            <p class="firstTitle">业务板块</p>
            <p class="secTitle">Business&nbsp;Segments</p>
        </div>

        <div class="swiperBox segmentsMsgBox">
            <div class="swiper-container2 segmentsMsgWidthControler">
                <div class="swiper-wrapper">
                    <div class="swiper-slide segmentsMsg"  v-for="item in segmentArr" :key="item.id">
                        <div class="imgBox" :type="item.type"  @click="toLink(item.link,item.indexArr)"></div>
                        <div class="imgmsgBox">
                            <p class="imgmsg">{{item.name}}</p>
                            <p class="imgsecmsg">{{item.secname}}</p>
                        </div>
                    </div>

                </div>
                <!-- 如果需要分页器 -->
                <div class="swiper-pagination"></div>
            </div>
        </div>

    </div>
</template>

<script>
    import 'swiper/dist/js/swiper'
    import 'swiper/dist/css/swiper.css'
    import Swiper from "swiper"

    export default{
        name:"Segments",
        data(){
            return{
                list: [

                ],
                listIndex: 0, //默认显示第几张图片
                timer: null, //定时器
                segmentArr:[
                    {id:'1',type:'1',indexArr:['2','1'],img:require('../../assets/css/images/1.png'),name:'全面预算内控平台v8',secname:'实现“全面、全额、全员”的精细化预算管理',link:'productsYs'},
                    {id:'2',type:'2',indexArr:['2','2'],img:require('../../assets/css/images/2.png'),name:'财务核算系统',secname:'实现医共体集团化财务管理，实现一本账管理',link:'Accounting'},
                    {id:'3',type:'3',indexArr:['2','3'],img:require('../../assets/css/images/3.png'),name:'电子档案系统',secname:'会计档案电子化采集、整理、归档、存储、检索、应用、鉴定整体解决方案',link:'Archives'},
                    {id:'4',type:'4',indexArr:['2','4'],img:require('../../assets/css/images/4.png'),name:'人员经费平台',secname:'奖金从上到下逐层分配；院外劳务费自动计税、工资消息推送；多维度统计查询',link:'personnelFunds'},
                    {id:'5',type:'5',indexArr:['2','5'],img:require('../../assets/css/images/5.png'),name:'绩效系统',secname:'打造医院全面、科学、合理的绩效管理和评价体系。',link:'Achievements'},
                    {id:'6',type:'6',indexArr:['2','6'],img:require('../../assets/css/images/6.png'),name:'应付款/供应商平台',secname:'实现货物票据、付款的综合管理，结合供应商形成数据一体化闭环。',link:'Financial'},
                    {id:'7',type:'7',indexArr:['2','7'],img:require('../../assets/css/images/7.png'),name:'一站式票务平台',secname:'采用现代信息化技术手段实现发票的无纸化管理',link:'ticketPlatform'},
                    {id:'8',type:'8',indexArr:['2','8'],img:require('../../assets/css/images/8.png'),name:'网银资金池',secname:'实现资金划转和归集，提高资金利用效率',link:'Ebank'},
                ]
            }
        },
        methods:{
            toLink(target,index){
                this.$router.push({
                    path:'/'+target,
                    query:{}
                })
                this.$emit('indexGetter',index)
                document.querySelector("#forHeader").scrollTop = 0
            },
            setTimer2() {
                this.timer = setInterval(() => {
                    this.listIndex++;
                    if (this.listIndex == this.list.length) {
                        this.listIndex = 0;
                    }
                }, 5000);
            },
        },
        mounted(){
            this.setTimer2(),
            setTimeout(()=>{
                new Swiper('.swiper-container2', {
                    direction: 'horizontal', // 垂直切换选项
                    mousewheel: true, //滚轮
                    runCallbacksOnInit:true,
                    observer:true,//修改swiper自己或子元素时，自动初始化swiper 
                    observeParents:true,//修改swiper的父元素时，自动初始化swiper 
                    loop: true, // 循环模式选项
                    slidesPerView :6,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true, // 分页器可以点击
                    },
                    autoplay: {
                        delay: 2500,    // 自动播放间隔，单位ms
                        disableOnInteraction: false, // 值为false表示用户操作swiper之后，不会停止播放，值true停止
                        waitForTransition: false,
                    }
                })
            },300)
        }
        
    }
</script>

<style scoped>
    .swiper-container2{
        margin-left: auto;
        margin-right: auto;
        position: relative;
        overflow: hidden;
        list-style: none;
        padding: 0;
        z-index: 1;
    }
    .swiper-pagination{
        position:static !important;
    }  
    .imgmsgBox{
        display: flex;
        flex-direction: column;
        align-items: center;
        width:90%;
    }
    .imgBox{
        border: 1px solid #fff;
        border-radius: 50%;
        padding: 20%;
        position: relative;
        margin-bottom: 40px;
        cursor: pointer;
        background-size: 50% 50%;
        background-repeat: no-repeat;
        background-position: center center;
    }
    .imgBox[type='1']{
        background-image: url('../../assets/css/images/1.png');
    }
    .imgBox[type='2']{
        background-image: url('../../assets/css/images/2.png');
    }
    .imgBox[type='3']{
        background-image: url('../../assets/css/images/3.png');
    }
    .imgBox[type='4']{
        background-image: url('../../assets/css/images/4.png');
    }
    .imgBox[type='5']{
        background-image: url('../../assets/css/images/5.png');
    }
    .imgBox[type='6']{
        background-image: url('../../assets/css/images/6.png');
    }
    .imgBox[type='7']{
        background-image: url('../../assets/css/images/7.png');
    }
    .imgBox[type='8']{
        background-image: url('../../assets/css/images/8.png');
    }
    .imgBox:hover{
        background-color: rgba(255, 255, 255, 0.543);
    }

    .imgmsg{
        color:#fff;
        font-size:18px;
    }
    .imgsecmsg{
        color:rgba(255, 255, 255, 0.614);
    }
    .swiper-slide{
        height:30% !important;
    }
    .swiper-slide img{
        width:87%;
    }
    .segmentsMsg{
        display: flex;
        width: 13%;
        flex-direction: column;
        align-items: center;
        align-content: space-between;
    }
    .segmentsMsgBox{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .segmentsMsgWidthControler{
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    .segmentsBox{
        background-image:url(../../assets/css/images/segments.png);
        margin-top:2px;
        height:761px;
        overflow:hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width:100%;
    }
    .segmentsBox .titleBox{
        width: 100%;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 2;
    }
    .segmentsBox .firstTitle{
        font-size: 30px;
        font-weight: 900;
    }
    .segmentsBox .secTitle{
        font-size: 16px;
        color:rgba(255, 255, 255, 0.614);
    }
    .swiperBox{
        width: 80%;
        margin: 0 auto;
    }
    .swiper-msg{
        background-color: #fff;
        border-radius: 4px;
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: absolute;
        bottom: -7%;
        padding:15px;
        left: 9%;
        cursor: pointer;
        height:80px;
        transition: all 0.3s;
        box-shadow: 1px 3px 6px rgb(141 141 141);
    }
    .swiper-msg:hover{
        height: 150px;
        background-color: #0080ff;
        color: #fff;
    }
    .swiper-msg:hover .swiper-msg-sectitle{
      white-space:inherit;
      -ms-text-overflow:inherit;
      text-overflow:inherit;
      overflow:inherit;
    }
    .swiper-msg-title{
        font-weight: 800;
        margin:0;
    }
    .swiper-msg-sectitle{
        width: 100%;
        white-space: nowrap;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow: hidden;
    }
</style>