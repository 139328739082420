<template>
  <div id="app">
    <Header :indexParam="indexParam" :headerShow="headerShow"></Header>
    <div id="forHeader"  :class="[headerShow?'forHeaderPush':'forHeaderPushBack']">
      <router-view @indexGetter="indexget"></router-view>
      <Footer @indexGetter="indexget"></Footer>
    </div>
  </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'

export default {
  name: 'App',
  data(){
    return{
      headerShow:true,
      t:0,
      p:0
    }
  },
  components: {
    Header,Footer
  },
  created(){
    this.indexParam = ''
  },
  mounted(){ 
    window.addEventListener('scroll', this.debounce(this.handleScroll,100), true)
  },
  methods:{
    indexget(param){
      this.indexParam = param
    },
    debounce(func, wait) {
        let timeout;      
        return function () {
            let context = this;
            let args = arguments;
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => {
                func.apply(context, args)
            }, wait);
        }  
    },
    //判断滚动方向
    handleScroll(e){
      // e.target.scrollTop < 100 ? 'up' : 'down'
      let that = this
      that.t = that.p;
      
      setTimeout(function () {
        that.p = e.target.scrollTop
        if(that.t <= that.p){ //如果是向下滚动
          that.headerShow = false
        }
        if(that.t > that.p){ //如果是向下滚动
          that.headerShow = true
        }
        if(that.p < 100){ //如果是向下滚动
          that.headerShow = true
        }
      }, 10);
      
      
      
    }
  },
}
</script>

<style>
#app {
    height:100%;
}
#forHeader{
    overflow-y: auto;
    transition: all 0.5s;
}
.forHeaderPush{
    height: calc(100% - 60px);
    top: 0px;
    position: relative;
}
.forHeaderPushBack{
    height: 100%;
    top: -60px;
    position: relative;
}
</style>
