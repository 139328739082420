<!-- <template>
    <div class="navBar">
       <div class="navphone navList">电话咨询</div>
       <div class="phoneMsg">
        <p class="phoneTitle">咨询电话</p>
        <p class="phoneNumber">0571-870-191-99</p>
        <i></i>
        <div class="phoneMsgJT"></div>
       </div>
       
       <div class="wechat navList">微信咨询</div>
       <div class="wechatMsg">
        <img src="../../assets/css/images/aboutRX.png" alt="">
        <div class="wechatMsgJT"></div>
       </div>
       
    </div>
</template>

<script>
    export default{
        name:"navBar",
        data(){
            return{
                
            }
        },
        methods:{
            
        },        
    }
</script>

<style scoped lang="scss">
    .wechatMsg img{
        width:200px;
    }
    .phoneMsgJT{
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-left-color: white;
        margin: 100px auto;
        position: fixed;
        top: 44.5%;
        right: 6.3%;
    }
    .wechatMsgJT{
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-left-color: white;
        margin: 100px auto;
        position: fixed;
        top: 54.5%;
        right: 6.3%;
    }
    .navBar{
        position:absolute;
        right:20px;
        top:50%;
        position:fixed;
        z-index:999;
    }
    .navphone{
        background-image: url('../../assets/css/images/navphone.png');
    }
    .navphone:hover{
        background-image: url('../../assets/css/images/navphonehover.png');
        background-color: rgb(19,133,236);
        color:#fff;
    }
    .navphone:hover~.phoneMsg{
        display: flex;
    }
    .phoneMsg{
        background-color: #fff;
        position: fixed;
        top: 50%;
        right: 7.3%;
        display: none;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        border-radius: 4px;

    }
    .phoneTitle{
        color: #333;
        font-size: 16px;
        font-weight: 600;
    }
    .wechatMsg{
        background-color: #fff;
        position: fixed;
        top: 58%;
        right: 7.3%;
        display: none;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        border-radius: 4px;

    }
    .phoneNumber{
        font-size: 20px;
        font-weight: 700;
        color: #1385EC;
    }
    .wechat{
        background-image: url('../../assets/css/images/wechat.png');
    }
    .wechat:hover{
        background-image: url('../../assets/css/images/wechathover.png');
        background-color: rgb(19,133,236);
        color:#fff;
    }
    .wechat:hover~.wechatMsg{
        display: flex;
    }
    .navList{
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        width: 100px;
        height: 100px;
        background-size: 30px;
        background-repeat: no-repeat;
        background-position: center 20px;
        padding-top: 60px;
        border-radius: 4px;
        margin-bottom: 1px;
        color: rgb(19,133,236);
    }
</style> -->


<template>
    <div class="navBar">

        <div class="navUp">
            
            <div class="navphone navList">电话咨询</div>

            <div class="phoneMsg">
                <div class="phoneMsgBox">
                    <p class="phoneTitle">咨询电话</p>
                    <p class="phoneNumber">0571-870-191-99</p>
                    <i></i>
                </div>
                <div class="phoneMsgJT"></div>
            </div>
            
            
            
        </div>
       
       <div class="navDown">
            <div class="wechat navList">微信咨询</div>

            <div class="wechatMsg">
                <div class="wechatMsgBox">
                    <img src="../../assets/css/images/aboutRX.png" alt="">
                </div>
                <div class="wechatMsgJT"></div>
            </div>

            
       </div>
       
       
    </div>
</template>

<script>
    export default{
        name:"navBar",
        data(){
            return{
                
            }
        },
        methods:{
            
        },        
    }
</script>

<style scoped lang="scss">
    
    .navBar{
        position:absolute;
        right:20px;
        top:50%;
        position:fixed;
        z-index:999;
        display:flex;
        flex-direction: column;
    }
    .navList{
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        width: 100px;
        height: 100px;
        background-size: 30px;
        background-repeat: no-repeat;
        background-position: center 20px;
        padding-top: 60px;
        border-radius: 4px;
        margin-bottom: 1px;
        color: #1385ec;
        float:right;
    }

    
    .navUp{
        
    }
    .navDown{
        
    }
    .phoneMsgJT{
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-left-color: white;
    }
    .wechatMsgJT{
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-left-color: white;
    }

    .navphone{
        background-image: url('../../assets/css/images/navphone.png');
    }
    .wechat{
        background-image: url('../../assets/css/images/wechat.png');
    }

    .navphone:hover{
        background-image: url('../../assets/css/images/navphonehover.png');
        background-color: rgb(19,133,236);
        color:#fff;
    }
    .wechat:hover{
        background-image: url('../../assets/css/images/wechathover.png');
        background-color: rgb(19,133,236);
        color:#fff;
    }

    .navphone:hover+.phoneMsg{
        display: flex;
    }
    .wechat:hover+.wechatMsg{
        display: flex;
    }
    .phoneMsg{
        display: none;
        justify-content: flex-end;
        align-items: center;
        float:left;
    }
    .wechatMsg{
        display: none;
        justify-content: flex-end;
        align-items: center;
        float:left;
    }

    .phoneMsgBox{
        background-color: #fff;
        align-items: center;
        padding: 15px;
        border-radius: 4px;
        width: 200px;
    }
    .wechatMsgBox{
        background-color: #fff;
        align-items: center;
        padding: 20px;
        border-radius: 4px;
        width: 200px;
    }

    .phoneTitle{
        color: #333;
        font-size: 16px;
        font-weight: 600;
    }
    .phoneNumber{
        font-size: 20px;
        font-weight: 700;
        color: #1385EC;
    }


    
    .wechatMsg img{
        width:100%;
    }
    
    

    
</style>