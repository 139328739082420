<template>
    <div class="featuresMsgBox">
        <div class="featuresMsg" v-for="(item,index) in features" :key="index">
            <img :src="item.img" alt="">
            <p class="featuresTitle">{{item.title}}</p>
            <p class="featuresInfo">{{item.msg}}</p>
        </div>
    </div>
</template>  

<script>
    
    export default{
        name:'FeaturesInfo',
        data(){
            return{

            }
        },
        props:['features']

    }
</script>

<style>
    .featuresTitle{
        font-size:16px;
        font-weight: 800;
    }
    .featuresMsgBox{
        width: 62%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .featuresMsg img{
        width:46px;
    }
    .featuresMsg img,.featuresMsg p{
        margin-bottom:10px;
    }
    .featuresMsg{
        width: 30.7%;
        height: 230px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 20px 20px 20px 20px;
        box-shadow: 0px 0px 11px #92c9ff;
        transition: all 0.5s;
        margin: 0 25px 50px 25px;
    }
    .featuresMsg:hover{
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        box-shadow: 0px 0px 20px #92c9ff;
    }
</style>