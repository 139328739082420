<template>
<div>
    <!-- ***************产品中心财务会计核算系统板块***************** -->
    <div id="financialHeader">
        <div class="coverfontWidthControler">
            <div class="coverfont">
                <p class="coverfont_title">财务会计核算系统</p>
                <p class="coverfont_sec_title">实现医共体集团化财务管理，实现一本账管理</p>
            </div>
            <img src="../assets/css/images/cwkjbanner.png" class="bannerImg">
        </div>
    </div>
    <div class="bodyProduct">
        <!-- ***************产品介绍***************** -->
        <Introduce :title="introduceTitle" :sectitle="introducesecTitle" :msg="introductionMsg"></Introduce>
        

        <!-- ***************产品特点***************** -->
        <Features :title="featuresTitle" :sectitle="featuressecTitle" :features="features"></Features>
        <productImg :productImgMsgArr="productImgMsgArr" :productImgLink="productImgLink"></productImg>
        <div class="productImgBox2">
            <div class="productImgBoxControler">
                <p class="productImgBox2MsgTitle">预算编制全员参与</p>
                <ul class="productImgBox2Msg">
                    <li class="productImgBox2MsgInfo">根据导入凭证、手工凭证、系统凭证自动进行业务处理，提供各项业务及接口的最佳实现。</li>
                    <li class="productImgBox2MsgInfo">需要进行平行记账的收支类业务，根据财务会计凭证自动生成预算会计凭证率达95%以上。</li>
                    <li class="productImgBox2MsgInfo">业务系统生成的财务会计凭证会计科目性质自动判断和自动生成预算会计凭证。</li>
                    <li class="productImgBox2MsgInfo">根据财务会计科目生成财务会计账和财务报表，根据预算会计科目生成财务会计账和决算报表。</li>
                    <li class="productImgBox2MsgInfo">多分录模式下，同一笔业务的财务会计核算和预算会计核算会计分录在同一记账凭证同一页面，上部为财务会计、下部为预算会计，各自保持平衡和相互对照关系，系统自动分别用蓝色和红色三角边框加以区分，便于查找。</li>
                </ul>
            </div>
            
            <img src="../assets/css/images/pxjz.png">
        </div>
    </div>
 </div>
</template>      

<script>
    import Introduce from '../components/Introduce'
    import Features from '../components/Features'
    import productImg from '../components/productImg'
    export default{
        name:"Accounting",
        data(){
            return{
                introduceTitle:'产品介绍',
                introducesecTitle:'Product&nbsp;introduction',
                introductionMsg:[{msg:'搭建双体系核算账簿，一套财务核算账簿的模式之下，搭建财务会计科目+预算会计科目的会计科目体系，凭证制单节点显示财务会计和预算会计两个凭证界面，对平行记账的经济业务根据财务会计凭证自动生成预算会计凭证；业务系统自动生成的财务凭证根据凭证中会计科目性质自动判断和生成预算会计凭证。根据财务会计科目形成财务会计账，出具财务会计报表，根据预算会计科目形成预算会计账和决算报告。'},
                {msg:'同时实现医共体集团化财务管理，实现一本账管理。制定医共体财务管理规则，制定医共体统一制定的科目体系，方便统一口径的数据统计析。'}],

                featuresTitle:'产品特点',
                featuressecTitle:'Product&nbsp;features',
                features:[
                    {id:'1',title:'医共体-全局',msg:'系统支持多级集团管控，实现所有医疗单位之间财务规则的统一',img:require('../assets/css/images/ygtqj.png'),group:'1'},
                    {id:'2',title:'标准化科目',msg:'搭建财务会计科目+预算会计科目的双科目标准化体系',img:require('../assets/css/images/bzhkm.png'),group:'1'},
                    {id:'3',title:'自动生成凭证、报表',msg:'根据财务会计科目形成财务会计账，出具财务会计报表，根据预算会计科目形成预算会计账和决算报告。',img:require('../assets/css/images/zdscpz.png'),group:'1'}],
                
                productImgMsgArr:[{title:'财务及预算报表',info:[{name:'财务报告与预算报告自动生成'},{name:'提供自定义报表'},{name:'辅助数据自动提取'}]}],
                productImgLink:require('../assets/css/images/pjhs.png'),
                
            }
        },
        mounted(){},
        components:{Introduce,Features,productImg}
    }
</script>

<style lang='scss' scoped>
    .productImgBoxControler{
        display: flex;
        flex-direction: column;
        width:50%;
    }
    .bodyProduct{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .productImgInfo{
        font-size:19px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .productImgInfo img{
        height:19px;
        margin-right:5px;
    }
    .productImgBox2{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width:80%;
        margin-top:100px;
    }
    .productImgBox2 img{
        width:478px;
        transition: all 0.5s;
    }
    .productImgBox2 img:hover{
        transform: scale(1.1);
    }
    .productImgBox2Msg{
        width:100%;
    }
    .productImgBox2Msg img{
        width:49%;
    }
    .productImgBox2MsgTitle{
        font-size:30px;
    }
    .productImgBox2MsgInfo{
        font-size: 16px;
        color: #767676;
    }
    .titleBox{
        margin-bottom:30px;
    }
</style>